import { Tooltip, Zoom } from '@mui/material';
import { Button, Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { GreatVibesRegular } from 'assets/fonts/GreatVibes-regular';
import { capitalizeFLetter } from '@jumbo/utils';
import moment from 'moment';
import 'moment/locale/pt-br';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';

export const CourseWithCertifiedList = ({
  coursesCertified,
  director,
  user,
}) => {
  moment.locale('pt-br');
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  window.pdfMake.vfs['GreatVibes-Regular.ttf'] = GreatVibesRegular;
  pdfMake.fonts = {
    Roboto: {
      normal:
        'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
      bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
      italics:
        'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
      bolditalics:
        'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
    },
    GreatVibes: {
      normal: 'GreatVibes-Regular.ttf',
      bold: 'GreatVibes-Regular.ttf', // Se não houver versão 'bold', você pode usar a mesma
      italics: 'GreatVibes-Regular.ttf',
      bolditalics: 'GreatVibes-Regular.ttf',
    },
  };

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: 'Fechar',
    });

  };

  const loadImageBase64 = (path) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', path);
      xhr.responseType = 'blob';
      xhr.send();
    });
  };

  const getDirectorForCertified = async (id, user, nomeDoCurso, curso) => {
    try {
      ApiService.get(`/diretores/curso-historico/${id}`)
        .then((response) => {
          gerarCertificado(
            user,
            response.data?.director,
            nomeDoCurso,
            curso
          );
        })
        .catch((error) => {
          if (error.response) {
            toast('error', error.response.data.error);
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const gerarCertificado = (pessoa, diretor, nomeDoCurso, curso) => {
    // Carrega todas as imagens em paralelo
    Promise.all([
      loadImageBase64('/images/certificado_moldura.png'), // Fundo
      loadImageBase64('/images/logositefeesp.png'), // Logo
      loadImageBase64(
        `${process.env.REACT_APP_API_KEY}/images/${diretor?.assinatura}`
      ), // Assinatura
      loadImageBase64('/images/ICONE_AREA_ENSINO.png')
    ])
      .then(([backgroundBase64, logoBase64, assinaturaBase64, iconArea]) => {
        const timestamp = curso.createdAt;

        // Formatar o timestamp para o formato desejado (DD de MMMM de YYYY)
        const formattedDate = moment(timestamp).format(
          'DD [de] MMMM [de] YYYY'
        );
        const docDefinition = {
          pageSize: 'A4',
          pageMargins: [60, 50, 60, 50],
          background: [
            {
              image: backgroundBase64,
              width: 590,
            },
          ],
          content: [
            {
              columns: [
                {
                  image: logoBase64,
                  width: 200,
                  alignment: 'left',
                  margin: [0, 0, 0, 10],
                },
               /*  {
                  text: 'ÁREA DE ENSINO',
                  alignment: 'right',
                  color: '#1473B9',
                  bold: true,
                  fontSize: 16,
                  margin: [0, 35, 0, 0],
                }, */
                {
                  image: iconArea,
                  width: 135,
                  //alignment: 'right',
                  margin: [135, 30, 0, 0],
                },
              ],
            },
            {
              stack: [
                {
                  text: 'CERTIFICADO',
                  style: 'header',
                  alignment: 'center',
                },
                {
                  text: [
                    {
                      text: 'Conferido a ',
                      style: 'content',
                      alignment: 'center',
                    },
                    {
                      text: `${pessoa.nome}, `,
                      style: 'content',
                      alignment: 'center',
                      bold: true,
                      font: 'GreatVibes',
                      fontSize: 28,
                    },
                    {
                      text: `CPF ${pessoa.cpf ?? '-'},`,
                      style: 'content',
                      alignment: 'center',
                    },
                    {
                      text: ' pela conclusão do curso: ',
                      style: 'content',
                      alignment: 'center',
                    },
                    {
                      text: `${nomeDoCurso}. `,
                      style: 'content',
                      alignment: 'center',
                      bold: true,
                    },
                    {
                      text: `Com carga horária de: ${curso?.Turma?.Curso?.carga_horaria ?? '-'}.`,
                      style: 'content',
                      alignment: 'center',
                    }
                  ],
                  margin: [0, 5, 0, 5],
                },

                {
                  text: `São Paulo, 10 de Dezembro de ${curso?.Turma?.ano}.`,
                  alignment: 'center',
                  margin: [0, 5, 0, 10],
                },

                {
                  alignment: 'center',
                  stack: [
                    {
                      image: assinaturaBase64,
                      width: 150, // Largura da imagem da assinatura
                      height: 60,
                      absolutePosition: { x: 50, y: 280 },
                    },
                    {
                      text: '______________________________',
                      absolutePosition: { x: 50, y: 315 },
                    },
                    {
                      text: `${diretor?.User?.nome}`,
                      bold: true,
                      absolutePosition: { x: 50, y: 330 },
                    },
                    {
                      text: `Diretor da Área de ${capitalizeFLetter(
                        diretor?.Area?.nome.toLowerCase()
                      )}`,
                      absolutePosition: { x: 50, y: 345 },
                    },
                  ],
                },
                {
                  /* text:
                    'São Paulo, ' +
                    new Date().toLocaleDateString('pt-BR') +
                    '\n(Emitido via sistema às ' +
                    new Date().toLocaleTimeString('pt-BR') +
                    ')', */
                  text: `São Paulo. Emitido em ${new Date().toLocaleDateString('pt-BR')}, às ${new Date().toLocaleTimeString('pt-BR')}, via sistema. Dúvidas: entrar em contato com a área de Ensino pelo telefone 011 3188-8383 ramal 237`,
                  style: 'dataEmissao',
                  alignment: 'center',
                  absolutePosition: { x: 50, y: 365 },
                },
              ],
            },
          ],
          defaultStyle: {
            font: 'Roboto', // Define a fonte padrão como Roboto
          },
          styles: {
            header: {
              fontSize: 24,
              bold: true,
              margin: [0, 10, 0, 0],
              color: '#1473B9',
            },
            content: {
              fontSize: 18,
              margin: [0, 10, 0, 10],
            },
            dataEmissao: {
              fontSize: 10,
              italics: true,
              margin: [0, 40, 0, 0],
            },
          },
        };

        // Gera o PDF com o pdfMake
        pdfMake.createPdf(docDefinition).download('certificado.pdf');
      })
      .catch((error) => {
        console.error('Erro ao carregar as imagens:', error);
      });
  };

  const columns = [
    {
      name: 'Turma.ano',
      label: 'Conclusão',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({
          align: 'center',
        }),
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <Typography variant={'body1'}>{value ?? '-'}</Typography>
          </>
        ),
      },
    },
    {
      name: 'Turma.Curso.nome',
      label: 'Nome',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({
          width: '40%',
        }),
        setCellProps: () => ({
          width: '40%',
        }),
      },
    },
    {
      name: 'Turma.local',
      label: 'Local',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({
          align: 'center',
        }),
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <Typography variant={'body1'}>{value ?? '-'}</Typography>
          </>
        ),
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({
          align: 'center',
        }),
        setCellProps: () => ({
          align: 'center',
        }),
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <Typography variant={'body1'}>{value ?? '-'}</Typography>
          </>
        ),
      },
    },
    {
      name: 'Turma.Curso.nome',
      label: 'Gerar Certificado',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            <Button
              disableElevation
              variant={'contained'}
              size={'small'}
              color={'info'}
              onClick={async () => {
                await getDirectorForCertified(
                  coursesCertified[tableMeta.rowIndex].curso_historico_id,
                  user,
                  value,
                  coursesCertified[tableMeta.rowIndex]
                );
              }}
              disabled={!director?.assinatura}
            >
              <Tooltip
                title='Gerar Certificado'
                TransitionComponent={Zoom}
                arrow
              >
                <DownloadIcon />
              </Tooltip>
            </Button>
          </>
        ),
      },
    },
  ];

  const options = {
    filterType: 'checkbox',
    selectableRows: 'none',
    search: false,
    download: false,
    print: false,
    sortFilterList: false,
    viewColumns: false,
    filter: false,
    expandableRows: false,
    expandableRowsHeader: false,
    enableNestedDataAccess: '.',
  };

  const components = {
    ExpandButton: function (props) {
      return <div style={{ width: '24px' }} />;
    },
  };

  return (
    <>
      {coursesCertified.length > 0 ? (
        <MUIDataTable
          data={coursesCertified}
          columns={columns}
          options={options}
          components={components}
        />
      ) : (
        <Typography>Nenhum histórico encontrado</Typography>
      )}
    </>
  );
};
