/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select as MuiSelect,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { updateInputValue } from '../../../utils/appHelpers';
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import HeaderBreadcrumbs from 'app/layouts/shared/headers/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { PermissionContext } from 'app/contexts/PermissionContext';
import styled from '@emotion/styled';
import Span from '@jumbo/shared/Span';
import { useDebouncedCallback } from 'beautiful-react-hooks';
import JumboSearchClasses from '@jumbo/components/JumboSearchClasses';
import ListRowBookTeam from 'app/components/ListRowBookTeam/ListRowBookTeam';
import VolunteerTeamList from './VolunteerTeamList';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';

export const Item = styled(Span)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}));

export const CSVStyled = styled(Item)`
  padding: 4px 10px;
  background: #0092d0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  gap: 0.5rem;
  cursor: pointer;

  svg {
    color: white;
  }
`;

export const generateYearOptions = () => {
  const arr = [];

  const startYear = 1980;
  const endYear = new Date().getFullYear();

  for (let i = endYear + 2; i >= startYear; i--) {
    arr.push(
      <MenuItem key={i} value={i.toString()}>
        {i}
      </MenuItem>
    );
  }

  return arr;
};

const BookTeamForm = () => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const animatedComponents = makeAnimated();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'transparent' : 'white',
      border: state.isFocused
        ? '1px solid #7352C7'
        : '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '4px',
      padding: '8px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'black',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#7352C7' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: 'lightblue',
        color: 'white',
      },
    }),
    menuPortal: (provided, state) => ({ ...provided, zIndex: 9999 }),
  };

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const generatePdf = (data, activity) => {
    const getStatus = (voluntario) => {
      if (voluntario?.data_fim) return 'Inativo';
      return 'Ativo';
    };

    const atividades = {
      ativo: [],
      inativo: [],
    };

    const atividadesExtras = {
      ativo: [],
      inativo: [],
    };

    /* const ordemAtividades = [
      'Dirigente',
      'Auxiliar de Dirigente',
      'Secretária',
      'Expositor',
      'Colaborador',
      ]; */

    // Ordem de prioridade das atividades
    const ordemAtividades = activity.map((item) => item?.nome);

    data.Equipe.forEach((equipe) => {
      const status = getStatus(equipe?.Atividade_Do_Voluntario);
      const statusKey = status?.toLowerCase();
      const atividadeNome =
        equipe?.Atividade_Do_Voluntario?.Atividades_Voluntario?.nome;

      if (ordemAtividades.includes(atividadeNome)) {
        if (!atividades[statusKey][atividadeNome]) {
          atividades[statusKey][atividadeNome] = [];
        }

        atividades[statusKey][atividadeNome].push({
          nome_do_voluntario:
            equipe?.Atividade_Do_Voluntario?.Voluntario?.User?.nome,
          codigo_antigo_voluntariado:
            equipe?.Atividade_Do_Voluntario?.Voluntario
              ?.codigo_antigo_voluntariado,
          codigo_voluntario_feesp:
            equipe?.Atividade_Do_Voluntario?.Voluntario
              ?.codigo_voluntario_feesp,
          data_inicio: equipe?.Atividade_Do_Voluntario?.data_inicio
        });
      } else {
        if (!atividadesExtras[statusKey][atividadeNome]) {
          atividadesExtras[statusKey][atividadeNome] = [];
        }
        atividadesExtras[statusKey][atividadeNome].push({
          nome_do_voluntario:
            equipe?.Atividade_Do_Voluntario?.Voluntario?.User?.nome,
          codigo_antigo_voluntariado:
            equipe?.Atividade_Do_Voluntario?.Voluntario
              ?.codigo_antigo_voluntariado,
          codigo_voluntario_feesp:
            equipe?.Atividade_Do_Voluntario?.Voluntario
              ?.codigo_voluntario_feesp,
          data_inicio: equipe?.Atividade_Do_Voluntario?.data_inicio
        });
      }
    });

    Object.keys(atividades).forEach((statusKey) => {
      atividades[statusKey] = ordemAtividades.reduce((acc, atividade) => {
        if (atividades[statusKey][atividade]) {
          acc[atividade] = atividades[statusKey][atividade];
        }
        return acc;
      }, {});

      atividades[statusKey] = {
        ...atividades[statusKey],
        ...atividadesExtras[statusKey],
      };
    });

    const totalAtivos = Object.keys(atividades.ativo).reduce(
      (total, atividadeNome) => total + atividades.ativo[atividadeNome].length,
      0
    );

    const totalAfastados = Object.keys(atividades.inativo).reduce(
      (total, atividadeNome) =>
        total + atividades.inativo[atividadeNome].length,
      0
    );

    const totalGeral = totalAtivos + totalAfastados;

    const docDefinition = {
      content: [
        {
          text: `ÁREA DE ${data.Areas.nome.toUpperCase()}`,
          bold: true,
          alignment: 'center',
          fontSize: 16,
        },
        {
          text: `COLABORADORES POR ASSISTÊNCIA`,
          alignment: 'center',
          fontSize: 14,
          margin: [0, 0, 0, 10],
        },
        {
          text: `LIVRO: ${data.codigo_do_livro ?? ''}`,
          alignment: 'center',
          fontSize: 12,
          margin: [0, 0, 0, 10],
        },
        /* {
          text: `DIA: ${data.dia_da_semana ?? ''} - INÍCIO: ${
            data.horario_inicio ?? ''
          } - TÉRMINO: ${data.horario_termino ?? ''} \n SALA: ${
            data.Sala?.nome ?? ''
          } - ANDAR: ${data.Sala?.andar ?? ''} - LOCAL: ${
            data.Sala?.local ?? ''
          }`,
          alignment: 'center',
          fontSize: 12,
        }, */
        {
          alignment: 'center',
          columns: [
            [
              { text: `DIA: ${data.dia_da_semana ?? ''}`, fontSize: 12 },
              { text: `INÍCIO: ${data.horario_inicio ?? ''}`, fontSize: 12 },
              { text: `TÉRMINO: ${data.horario_termino ?? ''}`, fontSize: 12 },
            ],
            [
              { text: `SALA: ${data.Sala?.nome ?? ''}`, fontSize: 12 },
              { text: `ANDAR: ${data.Sala?.andar ?? ''}`, fontSize: 12 },
              { text: `LOCAL: ${data.Sala?.local ?? ''}`, fontSize: 12 },
            ],
          ],
        },
        {
          canvas: [
            {
              type: 'line',
              x1: -40, y1: 0,
              x2: 555, y2: 0,
              lineWidth: 1,
              // dash: { length: 2, space: 2 }, // Linha pontilhada
            },
          ],
          margin: [0, 10, 0, 5],
        },
        {
          text: 'ATIVOS',
          alignment: 'center',
          bold: true,
          fontSize: 14,
          margin: [0, 10, 0, 0],
        },
        {
          table: {
            headerRows: 1,
            widths: ['auto', '*', 'auto'],
            body: [
              [
                { text: 'Número', bold: true },
                { text: 'Nome', bold: true },
                { text: 'Alocado desde', bold: true },
              ],
              ...Object.keys(atividades.ativo)
                .sort((a, b) => {
                  const ordemFixa = ['Diretor(a) de Assistência', 'Secretária'];
                  const indexA = ordemFixa.indexOf(a);
                  const indexB = ordemFixa.indexOf(b);

                  if (indexA !== -1 && indexB !== -1) return indexA - indexB;
                  if (indexA !== -1) return -1;
                  if (indexB !== -1) return 1;
                  return a.localeCompare(b);
                })
                .map((atividadeNome) => {
                  const voluntarios = atividades.ativo[atividadeNome];

                  return [
                    [{ text: atividadeNome, colSpan: 3, bold: true }, {}, {}],
                    ...voluntarios.map((voluntario, idx) => [
                      { text: voluntario?.codigo_antigo_voluntariado
                        ? voluntario?.codigo_antigo_voluntariado
                        : voluntario?.codigo_voluntario_feesp },
                      { text: voluntario.nome_do_voluntario || 'N/A' },
                      { text: formatDate(voluntario.data_inicio) },
                    ]),
                  ];
                })
                .flat(),
            ],
          },
          layout: 'lightHorizontalLines',
        },
        {
          text: `TOTAL DE ATIVOS: ${totalAtivos}`,
          alignment: 'left',
          bold: true,
          margin: [0, 5, 0, 10],
        },
        {
          canvas: [
            {
              type: 'line',
              x1: -40, y1: 0,
              x2: 555, y2: 0,
              lineWidth: 1,
              // dash: { length: 2, space: 2 }, // Linha pontilhada
            },
          ],
          margin: [0, 10, 0, 5],
        },
        {
          text: 'AFASTADOS',
          alignment: 'center',
          bold: true,
          fontSize: 14,
          margin: [0, 10, 0, 5],
        },
        {
          table: {
            headerRows: 1,
            widths: ['auto', '*', 'auto'],
            body: [
              [
                { text: 'Número', bold: true },
                { text: 'Nome', bold: true },
                { text: 'Alocado desde', bold: true },
              ],
              ...Object.keys(atividades.inativo).map((atividadeNome) => {
                const voluntarios = atividades.inativo[atividadeNome];

                return [
                  [{ text: atividadeNome, colSpan: 3, bold: true }, {}, {}],
                  ...voluntarios.map((voluntario, idx) => [
                    { text: voluntario?.codigo_antigo_voluntariado
                      ? voluntario?.codigo_antigo_voluntariado
                      : voluntario?.codigo_voluntario_feesp },
                    { text: voluntario.nome_do_voluntario || 'N/A' },
                    { text: formatDate(voluntario.data_inicio) },
                  ]),
                ];
                })
                .flat(),
            ],
          },
          layout: 'lightHorizontalLines',
        },
        {
          text: `TOTAL DE AFASTADOS: ${totalAfastados}`,
          alignment: 'left',
          bold: true,
          margin: [0, 5, 0, 10],
        },
        {
          text: `TOTAL GERAL (ATIVOS + AFASTADOS): ${totalGeral}`,
          alignment: 'left',
          bold: true,
          margin: [0, 10, 0, 0],
        },
      ],
    };

    pdfMake.createPdf(docDefinition).open();
  };

  const formatDate = (data) => {
    if (!data) return 'N/A';
    const dataObj = new Date(data);
    if (isNaN(dataObj)) return 'N/A';
    // Ajustar fuso horário para evitar diferença
    return dataObj.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
  };

  const generateAttendancePdf = (data, activity) => {
    const getStatus = (voluntario) => {
      if (voluntario?.data_fim) return 'Inativo';
      return 'Ativo';
    };

    const atividades = {
      ativo: [],
      inativo: [],
    };

    const atividadesExtras = {
      ativo: [],
      inativo: [],
    };

    /* const ordemAtividades = [
      'Dirigente',
      'Auxiliar de Dirigente',
      'Secretária',
      'Expositor',
      'Colaborador',
    ]; */

    // Ordem de prioridade das atividades
    const ordemAtividades = activity.map((item) => item?.nome);

    data.Equipe.forEach((equipe) => {
      const status = getStatus(equipe?.Atividade_Do_Voluntario);
      const statusKey = status?.toLowerCase();
      const atividadeNome =
        equipe?.Atividade_Do_Voluntario?.Atividades_Voluntario?.nome;

      if (ordemAtividades.includes(atividadeNome)) {
        if (!atividades[statusKey][atividadeNome]) {
          atividades[statusKey][atividadeNome] = [];
        }

        atividades[statusKey][atividadeNome].push({
          nome_do_voluntario:
            equipe?.Atividade_Do_Voluntario?.Voluntario?.User?.nome,
          codigo_antigo_voluntariado:
            equipe?.Atividade_Do_Voluntario?.Voluntario
              ?.codigo_antigo_voluntariado,
          codigo_voluntario_feesp:
            equipe?.Atividade_Do_Voluntario?.Voluntario
              ?.codigo_voluntario_feesp,
        });
      } else {
        if (!atividadesExtras[statusKey][atividadeNome]) {
          atividadesExtras[statusKey][atividadeNome] = [];
        }
        atividadesExtras[statusKey][atividadeNome].push({
          nome_do_voluntario:
            equipe?.Atividade_Do_Voluntario?.Voluntario?.User?.nome,
          codigo_antigo_voluntariado:
            equipe?.Atividade_Do_Voluntario?.Voluntario
              ?.codigo_antigo_voluntariado,
          codigo_voluntario_feesp:
            equipe?.Atividade_Do_Voluntario?.Voluntario
              ?.codigo_voluntario_feesp,
        });
      }
    });

    Object.keys(atividades).forEach((statusKey) => {
      atividades[statusKey] = ordemAtividades.reduce((acc, atividade) => {
        if (atividades[statusKey][atividade]) {
          acc[atividade] = atividades[statusKey][atividade];
        }
        return acc;
      }, {});

      atividades[statusKey] = {
        ...atividades[statusKey],
        ...atividadesExtras[statusKey],
      };
    });

    const daysWeekLabel = {
      Domingo: 0,
      Segunda: 1,
      Terça: 2,
      Quarta: 3,
      Quinta: 4,
      Sexta: 5,
      Sábado: 6,
    };

    const monthNames = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    function getWeekdayOfMonth(year, month, daysWeek) {
      let date = new Date(year, month, 1);
      const weekdays = [];
      const day = daysWeekLabel[daysWeek];
      while (date.getMonth() === month) {
        if (date.getDay() === day) {
          weekdays.push(new Date(date));
        }
        date.setDate(date.getDate() + 1);
      }

      return weekdays;
    }

    const weekdays = getWeekdayOfMonth(
      selectedYear,
      selectedMonth,
      books.dia_da_semana
    ).map((date) => {
      // Formatando a data como 'dd/mm'
      return date.toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
      });
    });

    console.log('ativades', atividades);

    const docDefinition = {
      content: [
        {
          text: `ÁREA DE ASSISTÊNCIA ESPIRITUAL`,
          alignment: 'center',
          bold: true,
          fontSize: 14,
        },
        {
          columns: [
            {
              text: `FOLHA DE PRESENÇA DO LIVRO  ${data.codigo_do_livro}`,
              alignment: 'left',
              fontSize: 14,
              bold: true,
              margin: [0, 10, 0, 10],
            },
            {
              text: `${monthNames[selectedMonth]}/${selectedYear}`,
              alignment: 'right',
              fontSize: 14,
              bold: true,
              margin: [0, 10, 0, 10],
            },
          ],
        },
        /* {
          text: `DIA: ${data.dia_da_semana ?? ''} - INÍCIO: ${
            data.horario_inicio ?? ''
          } - TÉRMINO: ${data.horario_termino ?? ''} \n SALA: ${
            data.Sala?.nome ?? ''
          } - ANDAR: ${data.Sala?.andar ?? ''} - LOCAL: ${
            data.Sala?.local ?? ''
          }`,
          alignment: 'center',
          fontSize: 12,
        }, */
        /* {
          table: {
            widths: ['*', '*'],  // Define duas colunas de tamanho igual
           
          },
          alignment: 'center',
        }, */
        {
          alignment: 'center',
          columns: [
            [
              { text: `DIA: ${data.dia_da_semana ?? ''}`, fontSize: 12 },
              { text: `INÍCIO: ${data.horario_inicio ?? ''}`, fontSize: 12 },
              { text: `TÉRMINO: ${data.horario_termino ?? ''}`, fontSize: 12 },
            ],
            [
              { text: `SALA: ${data.Sala?.nome ?? ''}`, fontSize: 12 },
              { text: `ANDAR: ${data.Sala?.andar ?? ''}`, fontSize: 12 },
              { text: `LOCAL: ${data.Sala?.local ?? ''}`, fontSize: 12 },
            ],
          ],
        },
        {
          text: 'ATIVOS',
          alignment: 'center',
          bold: true,
          fontSize: 14,
          margin: [0, 10, 0, 0],
        },
        {
          table: {
            headerRows: 1,
            widths: ['auto', '*', ...weekdays.map(() => 'auto'), 'auto'],
            body: [
              [
                { text: 'Número', bold: true },
                { text: 'Nome', bold: true },
                ...weekdays.map((date) => ({ text: date, bold: true })),
                { text: 'Observação', bold: true },
              ],
              ...Object.keys(atividades.ativo)
                .sort((a, b) => {
                  // Definir ordem: Diretor > Secretária > Resto em ordem alfabética
                  const ordemFixa = ['Diretor(a) de Assistência', 'Secretária'];
              
                  const indexA = ordemFixa.indexOf(a);
                  const indexB = ordemFixa.indexOf(b);
              
                  if (indexA !== -1 && indexB !== -1) {
                    return indexA - indexB;
                  }
              
                  if (indexA !== -1) return -1;
                  if (indexB !== -1) return 1;
              
                  return a.localeCompare(b);
                })
                .map((atividadeNome) => {
                  const voluntarios = atividades.ativo[atividadeNome];

                  return [
                    [
                      {
                        text: atividadeNome,
                        bold: true,
                        alignment: 'left',
                        colSpan: 1 + weekdays.length + 1,
                      },
                      ...Array(1 + weekdays.length + 1).fill({}),
                    ],

                    // Iterando sobre os voluntários para criar linhas separadas
                    ...voluntarios.map((voluntario, idx) => [
                      {
                        text: voluntario?.codigo_antigo_voluntariado
                          ? voluntario?.codigo_antigo_voluntariado
                          : voluntario?.codigo_voluntario_feesp,
                      }, // Índice do voluntário na atividade
                      { text: voluntario.nome_do_voluntario }, // Nome do voluntário
                      ...weekdays.map(() => ({ text: '(       )' })), // Colunas de presença nas quartas-feiras
                      { text: '' }, // Observação (se houver)
                    ]),
                  ];
                })
                .flat(),
            ],
          },
          layout: 'lightHorizontalLines', // Estilo de linhas horizontais leves
        },
      ],
    };

    pdfMake.createPdf(docDefinition).open();
  };

  const { theme } = useJumboTheme();
  const { showDialog, hideDialog } = useJumboDialog();

  const navigate = useNavigate();
  let { state, pathname } = useLocation();

  const Swal = useSwalWrapper();
  const toast = (variant, message) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: 'Fechar',
    });
  };

  const lg = useMediaQuery(theme.breakpoints.down('lg'));
  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const initialValues = {
    area_id: '',
    departamento_id: '',
    codigo_do_livro: '',
    dia_da_semana: '',
    horario_inicio: '',
    horario_termino: '',
    sala_id: '',
  };

  const { id } = useParams();
  const [areas, setAreas] = useState([]);
  const [books, setBooks] = useState(initialValues);
  const [searchTerm, setSearchTerm] = useState(null);
  const [activity, setActivity] = useState([]);
  const [activityByOrder, setActivityByOrder] = useState([]);

  const [addVolunteers, setAddVolunteers] = useState(null);
  const [volunteersTeam, setVolunteersTeam] = useState([]);

  const [isInputFocused, setIsInputFocused] = useState(false);
  const [includeVolunteerToTeam, setIncludesVolunteerToTeam] = useState({
    include: false,
    volunteer: [],
  });
  const [inputsByVolunteer, setInputsByVolunteer] = useState({
    data_inicio: '',
    data_fim: '',
    motivo_encerramento: '',
  });
  const [selectedActivities, setSelectedActivities] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const { hasPermission } = useContext(PermissionContext);

  const canCreate = hasPermission('Voluntariado', 'criar');
  const canEdit = hasPermission('Voluntariado', 'editar');
  const isDisabled = id ? !canEdit : !canCreate;

  const storedUserId = localStorage.getItem('storedUserId');

  useEffect(() => {
    if (searchTerm == undefined || searchTerm == '') {
      setAddVolunteers(null);
      return;
    }

    const delayDebounceFn = setTimeout(() => {
      searchVolunteer(searchTerm);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const searchVolunteer = useCallback(
    async (search) => {
      try {
        ApiService.get(`/voluntarios`, {
          params: {
            search: search,
            area_id: books?.area_id,
            departamento_id: books?.departamento_id,
            livro_id: id,
            searchVolunteerInArea: true,
          },
        })
          .then((response) => {
            console.log(response.data);
            setAddVolunteers(response.data);
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('error', error.message);
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    [books]
  );

  const getAreas = useCallback(async () => {
    try {
      ApiService.get(`/areas`)
        .then((response) => {
          setAreas(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getActivities = useCallback(async () => {
    try {
      ApiService.get(`/atividades-voluntarios?orderByOrder=${true}`)
        .then((response) => {
          console.log(response.data);
          setActivity(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getActivitiesByOrder = useCallback(async () => {
    try {
      ApiService.get(`/atividades-voluntarios`)
        .then((response) => {
          console.log(response.data);
          setActivityByOrder(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const getBookTeamsById = useCallback(async () => {
    try {
      ApiService.get(`/livros/${id}`)
        .then((response) => {
          console.log(response.data);

          setBooks(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (id) {
      getBookTeamsById();
      getActivities();
      getActivitiesByOrder();
    }
    getAreas();
  }, []);

  useEffect(() => {
    Swal.close();
  }, [pathname]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (id) {
      console.log('enviou', values);
      await ApiService.put(`/livros/${id}`, { values })
        .then((response) => {
          toast('success', 'Turma atualizada com sucesso');
        })
        .catch((error) => {
          toast('error', error.response.data.error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } else {
      await ApiService.post('/livros', { values })
        .then((response) => {
          toast('success', 'Criado com sucesso');
          resetForm();
        })
        .catch((error) => {
          toast('error', error.response.data.error);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    }

    setSubmitting(false);
  };

  const handleChangeSearch = useDebouncedCallback((event) => {
    setSearchTerm(event.target.value);
  });

  React.useEffect(() => {
    setSearchTerm(searchTerm);
  }, [searchTerm]);

  React.useEffect(() => {
    return () => handleChangeSearch.cancel();
  });

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const updateVolunteerList = useCallback(async (data) => {
    setVolunteersTeam([...data]);
    setAddVolunteers(null);
    getBookTeamsById();
  }, []);

  const optionsVolunteerActivities = activityByOrder?.map((result) => {
    return {
      value: result.atividade_voluntario_id,
      label: result.nome,
    };
  });

  const includeVolunteer = useCallback(
    (record, area_id, departamento_id, storedUserId) => {
      console.log(record);
      console.log(selectedActivities);

      console.log(inputsByVolunteer);

      if (!selectedActivities?.value) {
        toast(
          'error',
          'Selecione uma atividade antes de incluir o voluntário.'
        );
        return;
      }

      if (!inputsByVolunteer.data_inicio) {
        console.log('Error');
        toast('error', 'Preencha data de início.');
        return;
      }

      try {
        setIsButtonDisabled(true);
        ApiService.post(
          `/equipes/livro/${id}/voluntario/${record.voluntario_id}`,
          {
            atividade_voluntario_id: selectedActivities.value,
            data_inicio: inputsByVolunteer?.data_inicio,
            data_fim: inputsByVolunteer?.data_fim
              ? inputsByVolunteer.data_fim
              : null,
            motivo_encerramento: inputsByVolunteer?.motivo_encerramento
              ? inputsByVolunteer.motivo_encerramento
              : null,
            departamento_id,
            area_id,
            id_quem_aceitou: storedUserId,
          }
        )
          .then((response) => {
            let newArray = volunteersTeam;
            newArray.push(response.data);
            updateVolunteerList(newArray);
            toast('success', 'Voluntário adicionado com sucesso');
            setIsButtonDisabled(false);
            setInputsByVolunteer({
              data_inicio: '',
              data_fim: '',
              motivo_encerramento: '',
            });
            setSelectedActivities({});
            setIncludesVolunteerToTeam(false);
          })
          .catch((error) => {
            if (error.response) {
              toast('error', error.response.data.error);
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('error', error.message);
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    [selectedActivities, inputsByVolunteer]
  );

  return (
    <JumboContentLayout
      header={
        <HeaderBreadcrumbs
          id={id}
          title={'Livro (Equipe)'}
          subtitle={'Inclusão de Colaboradores no Livro (Equipe)'}
          titleUrl={'/app/listar-equipes-inserir-voluntario'}
        />
      }
      layoutOptions={layoutOptions}
    >
      {lg && (
        <Stack spacing={2} direction={'row'} sx={{ mb: 3, mt: -2 }}></Stack>
      )}
      <Paper sx={{ p: '20px' }}>
        <Box
          display={'flex'}
          alignItems={'flex-start'}
          justifyContent={'space-between'}
          mb={3}
        >
          <Button
            color='success'
            type='submit'
            variant='contained'
            size='small'
            sx={{ maxWidth: { md: '200px' } }}
            onClick={() =>
              navigate(
                state?.backUrl || '/app/listar-equipes-inserir-voluntario'
              )
            }
          >
            Voltar
          </Button>

          <Box display={'flex'} flexDirection={'column'} gap={3}>
            <Box display={'flex'} alignItems={'flex-start'} gap={3}>
              <FormControl variant='outlined' sx={{ minWidth: 120 }}>
                <InputLabel>Mês</InputLabel>
                <MuiSelect
                  value={selectedMonth}
                  onChange={(e) => setSelectedMonth(e.target.value)}
                  label='Mês'
                  size={'small'}
                >
                  {Array.from({ length: 12 }, (_, i) => (
                    <MenuItem key={i} value={i}>
                      {new Date(0, i).toLocaleString('default', {
                        month: 'long',
                      })}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>

              <FormControl variant='outlined' sx={{ minWidth: 120 }}>
                <InputLabel>Ano</InputLabel>
                <MuiSelect
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  label='Ano'
                  size={'small'}
                >
                  {Array.from({ length: 10 }, (_, i) => (
                    <MenuItem key={i} value={new Date().getFullYear() - 5 + i}>
                      {new Date().getFullYear() - 5 + i}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>

              <Button
                size={'small'}
                variant='contained'
                onClick={() => {
                  generateAttendancePdf(books, activity);
                }}
                disabled={!selectedMonth && !selectedYear}
              >
                Lista de Presença
              </Button>

              <Button
                size={'small'}
                variant='contained'
                onClick={() => {
                  generatePdf(books, activity);
                }}
              >
                Lista de Participantes
              </Button>
            </Box>

            <Box display={'flex'} justifyContent={'flex-end'}></Box>
          </Box>
        </Box>
        <Formik
          initialValues={books}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, handleChange, setFieldValue }) => {
            const selectedArea = areas.find(
              (area) => area.area_id === values.area_id
            );
            return (
              <Form style={{ width: '100%' }} noValidate autoComplete='off'>
                <Grid container spacing={3} alignContent={'center'}>
                  <Grid item xs={3}>
                    <JumboTextField
                      fullWidth
                      name='codigo_do_livro'
                      label='Código do Livro'
                      value={updateInputValue(books, values, 'codigo_do_livro')}
                      sx={{ background: '#ffffff' }}
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='area_id'>
                        Área
                      </InputLabel>
                      <JumboSelectField
                        labelId='area_id'
                        id='area_id'
                        name='area_id'
                        value={updateInputValue(books, values, 'area_id')}
                        label='Área'
                        disabled={true}
                        notched
                      >
                        <MenuItem value={''}>Sem seleção</MenuItem>
                        {areas.length > 0 &&
                          areas.map((x) => (
                            <MenuItem value={x.area_id}>{x.nome}</MenuItem>
                          ))}
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='departamento_id'>
                        Departamento
                      </InputLabel>
                      <JumboSelectField
                        labelId='departamento_id'
                        id='departamento_id'
                        name='departamento_id'
                        value={updateInputValue(
                          books,
                          values,
                          'departamento_id'
                        )}
                        label='Departamento'
                        disabled={true}
                        sx={{ background: '#ffffff' }}
                        notched
                      >
                        <MenuItem value={''}>Sem seleção</MenuItem>
                        {(selectedArea?.departamento || []).map((x) => (
                          <MenuItem value={x.departamento_id}>
                            {x.nome}
                          </MenuItem>
                        ))}
                      </JumboSelectField>
                    </FormControl>
                  </Grid>

                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='sala_id'>
                        Sala
                      </InputLabel>
                      <JumboSelectField
                        labelId='sala_id'
                        id='sala_id'
                        name='sala_id'
                        value={books.sala_id}
                        label='Sala'
                        disabled={true}
                        notched
                      >
                        <MenuItem value={''}>Sem seleção</MenuItem>
                        <MenuItem value={books.sala_id}>
                          {books?.Sala?.nome}
                        </MenuItem>
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='local'>
                        Local
                      </InputLabel>
                      <JumboSelectField
                        labelId='local'
                        id='local'
                        name='local'
                        value={books?.Sala?.local || ''}
                        label='Local'
                        disabled={true}
                        sx={{ background: '#ffffff' }}
                        notched
                      >
                        <MenuItem value={''}>Sem seleção</MenuItem>

                        <MenuItem value={books?.Sala?.local}>
                          {books?.Sala?.local}
                        </MenuItem>
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='andar'>
                        Andar
                      </InputLabel>
                      <JumboSelectField
                        labelId='andar'
                        id='andar'
                        name='andar'
                        value={books?.Sala?.andar || ''}
                        label='andar'
                        disabled={true}
                        sx={{ background: '#ffffff' }}
                        notched
                      >
                        <MenuItem value={''}>Sem seleção</MenuItem>

                        <MenuItem value={books?.Sala?.andar}>
                          {books?.Sala?.andar}
                        </MenuItem>
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputLabel shrink id='dia_da_semana'>
                        Dia da semana
                      </InputLabel>
                      <JumboSelectField
                        labelId='dia_da_semana'
                        id='dia_da_semana'
                        name='dia_da_semana'
                        value={updateInputValue(books, values, 'dia_da_semana')}
                        label='Dia da semana'
                        disabled={true}
                        notched
                      >
                        <MenuItem value={'Domingo'}>Domingo</MenuItem>
                        <MenuItem value={'Segunda'}>Segunda</MenuItem>
                        <MenuItem value={'Terça'}>Terça</MenuItem>
                        <MenuItem value={'Quarta'}>Quarta</MenuItem>
                        <MenuItem value={'Quinta'}>Quinta</MenuItem>
                        <MenuItem value={'Sexta'}>Sexta</MenuItem>
                        <MenuItem value={'Sábado'}>Sábado</MenuItem>
                      </JumboSelectField>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputMask
                        mask='99:99'
                        value={updateInputValue(
                          books,
                          values,
                          'horario_inicio'
                        )}
                        onChange={handleChange}
                        disabled={true}
                      >
                        {() => (
                          <JumboTextField
                            fullWidth
                            name='horario_inicio'
                            label='Início'
                            disabled={true}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      </InputMask>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputMask
                        mask='99:99'
                        value={updateInputValue(
                          books,
                          values,
                          'horario_termino'
                        )}
                        onChange={handleChange}
                        disabled={true}
                      >
                        {() => (
                          <JumboTextField
                            fullWidth
                            name='horario_termino'
                            label='Término'
                            disabled={true}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        )}
                      </InputMask>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant={'h4'} mb={1}>
                      Adicionar colaborador
                    </Typography>

                    <Grid item xs={1} sx={{ textAlign: 'center' }}>
                      <JumboSearchClasses
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder='Busque por Nome, CPF, Celular ou E-mail.'
                        sx={{
                          width: '440px',
                          marginBottom: '1rem',
                        }}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        disabled={isDisabled}
                      />
                    </Grid>

                    {addVolunteers != null && addVolunteers.length > 0 && (
                      <ListRowBookTeam
                        data={addVolunteers}
                        updateVolunteerList={(data) =>
                          updateVolunteerList(data)
                        }
                        volunteersTeam={volunteersTeam}
                        setSearchTerm={setSearchTerm}
                        area_id={values.area_id}
                        departamento_id={values.departamento_id}
                        setIncludesVolunteerToTeam={setIncludesVolunteerToTeam}
                      />
                    )}
                    {addVolunteers != null && addVolunteers.length == 0 && (
                      <Typography>Sem resultados correspondentes</Typography>
                    )}

                    {addVolunteers != null &&
                      addVolunteers.length == 1 &&
                      addVolunteers[0]?.naTurma && (
                        <Typography>Aluno já está no livro (equipe)</Typography>
                      )}

                    {includeVolunteerToTeam.include && (
                      <Grid container spacing={2}>
                        <Grid item xs={5}>
                          <JumboTextField
                            fullWidth
                            name='nome'
                            label='Nome'
                            value={includeVolunteerToTeam.volunteer?.User?.nome}
                            sx={{ background: '#ffffff' }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <div style={{ position: 'relative' }}>
                            <Select
                              id='select-two'
                              menuPortalTarget={document.body}
                              styles={customStyles}
                              options={optionsVolunteerActivities}
                              components={animatedComponents}
                              onChange={(e) => setSelectedActivities(e)}
                              placeholder='Selecione Atividade'
                              isDisabled={
                                !hasPermission(
                                  'Alocação de Colaboradores',
                                  'editar'
                                )
                              }
                            />

                            <Typography
                              style={{
                                position: 'absolute',
                                top: '-9px',
                                left: '10px',
                                background: '#ffffff',
                                padding: '0 5px',
                                color: '#8595A6',
                                fontSize: '0.7rem',
                                fontFamily: 'NoirPro,Arial',
                              }}
                            >
                              Selecione atividade
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <JumboTextField
                            fullWidth
                            name='data_inicio'
                            type='date'
                            label='Admissão'
                            value={inputsByVolunteer.data_inicio}
                            onChange={(e) => {
                              setInputsByVolunteer({
                                ...inputsByVolunteer,
                                data_inicio: e.target.value,
                              });
                            }}
                            sx={{ background: '#ffffff' }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={
                              !hasPermission(
                                'Alocação de Colaboradores',
                                'editar'
                              )
                            }
                          />
                        </Grid>
                        {/* <Grid item xs={2}>
                          <JumboTextField
                            fullWidth
                            name='data_fim'
                            type='date'
                            label='Saída'
                            value={inputsByVolunteer.data_fim}
                            onChange={(e) => {
                              setInputsByVolunteer({
                                ...inputsByVolunteer,
                                data_fim: e.target.value,
                              });
                            }}
                            sx={{ background: '#ffffff' }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            disabled={
                              !hasPermission(
                                'Alocação de Colaboradores',
                                'editar'
                              )
                            }
                          />
                        </Grid>

                        <Grid item xs={5}>
                          <JumboTextField
                            fullWidth
                            name='motivo_encerramento'
                            label='Motivo'
                             value={inputsByVolunteer.motivo_encerramento}
                            onChange={(e) => {
                              setInputsByVolunteer({
                                ...inputsByVolunteer,
                                motivo_encerramento: e.target.value,
                              });
                            }}
                            disabled={
                              !hasPermission(
                                'Alocação de Colaboradores',
                                'editar'
                              )
                            }
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid> */}

                        <Grid item xs={12}>
                          <Box display={'flex'} justifyContent={'center'}>
                            <Button
                              size={'small'}
                              variant={'contained'}
                              color={'success'}
                              disableElevation
                              onClick={() =>
                                includeVolunteer(
                                  includeVolunteerToTeam.volunteer,
                                  values.area_id,
                                  values.departamento_id,
                                  storedUserId
                                )
                              }
                              disabled={
                                !hasPermission(
                                  'Alocação de Colaboradores',
                                  'editar'
                                ) || isButtonDisabled
                              }
                              sx={{
                                minWidth: 78,
                                textTransform: 'none',
                                p: (theme) => theme.spacing(0.5, 1.5),
                              }}
                            >
                              Cadastrar
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    )}

                    <Typography
                      variant={'h4'}
                      mb={1}
                      sx={{ marginTop: '2rem' }}
                    >
                      Lista de colaboradores no livro (equipe)
                    </Typography>

                    <VolunteerTeamList
                      volunteersTeam={volunteersTeam}
                      setVolunteersTeam={setVolunteersTeam}
                      handleInputFocus={handleInputFocus}
                      handleInputBlur={handleInputBlur}
                      getBookTeamsById={getBookTeamsById}
                    />
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};

export default BookTeamForm;
