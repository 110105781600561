import React, { useCallback, useState, useContext } from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import Avatar from "@mui/material/Avatar";
import {Accordion, Card, Tooltip, Typography, Grid, Chip, Modal, Fade, Backdrop} from "@mui/material";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { ApiService } from 'app/servicesTwo/ApiService';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import Zoom from '@mui/material/Zoom';
import { TextService } from 'app/servicesTwo/TextService';
import { PermissionContext } from 'app/contexts/PermissionContext';
import { LinkStyle } from '../users/UserItem';
import { useLocation, useNavigate } from 'react-router-dom'
import Div from '@jumbo/shared/Div/Div';
import ModalAuthorizeFile from '../coursesIntro/ModalAuthorizeFile';
import ModalTransferClassEvaluate from './ModalTransferClassEvaluate';
import MatchCandidate from 'app/components/MatchCandidate/MatchCandidate';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80em',
   
};

export const getChipColor = (diaDaSemana) => {
    switch (diaDaSemana) {
        case 'Segunda':
            return 'primary';
        case 'Terça':
            return 'secondary';
        case 'Quarta':
            return 'success';
        case 'Quinta':
            return 'success';
        case 'Sexta':
            return 'info';
        case 'Sábado':
            return 'error';
      
      default:
        return 'default';
    }
};

const EvaluateDocumentsItem = ({ item, setUsersRegistration, usersRegistration, getClasses, classes, setClasses, page, perPage}) => {
    
    const location = useLocation();
    const { hasPermission } = useContext(PermissionContext);
    const [open, setOpen] = useState(false);
    const [openModalTransferClass, setOpenModalTransferClass] = useState(false);

    const navigate = useNavigate();

    const Swal = useSwalWrapper();
    const toast = (variant, message) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            onOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: variant,
            title: message,
            showCloseButton: true, // botão de fechar
            closeButtonAriaLabel: 'Fechar',
        });
    };

    const modalAlert = (curso_introdutorio_historico_id) => {
        Swal.fire({
            title: 'Tem certeza que deseja apagar?',
            text: "Não será póssível reverter a ação!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                removeStudentFromCourseIntro(curso_introdutorio_historico_id);
            }
        });
    };

    /* const modalAlertInsert = (userInfo) => {
        Swal.fire({
            title: 'Tem certeza que deseja incluir na turma?',
            text: "Não será póssível reverter a ação!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim!',
            cancelButtonText: 'Não!',
            reverseButtons: true,
        }).then(result => {
            if (result.value) {
                transferStudentFromCourseIntro(userInfo);
            }
        });
    }; */

    function removeById(arr, id) {
        const objWithIdIndex = arr.findIndex((obj) => obj.curso_introdutorio_historico_id === id);
      
        if (objWithIdIndex > -1) {
          arr.splice(objWithIdIndex, 1);
        }
      
        return arr;
    }

    function updateById(arr, id, status) {
        const updatedClass = arr.find((entry) => entry.curso_introdutorio_historico_id === id)
        if(updatedClass) {
            updatedClass.status = status
        }

        return arr;
    }

    console.log(item)

    const removeStudentFromCourseIntro = useCallback(async (curso_introdutorio_historico_id) => {
    try {
        ApiService.delete(`/cursos-introdutorios/pessoa/${curso_introdutorio_historico_id}`,)
        .then((response) => {
            toast('success', 'Removido com sucesso');

            const updatedStudentsArray = removeById(usersRegistration, curso_introdutorio_historico_id)
            setUsersRegistration(updatedStudentsArray);
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('error', error.message);
            }
        })
    } catch (err) {
        console.log(err);
        toast('error', 'Ocorreu um erro');
    }
    }, [usersRegistration, setUsersRegistration]);

    const transferStudentFromCourseIntro = useCallback(async (classesInfo, userInfo) => {
        
        const turmaId = classesInfo.turmas_id
        const pessoa_id = userInfo.pessoa_id
        const introHistoricoId = userInfo.curso_introdutorio_historico_id
        try {
            ApiService.post(`/turmas-inserir/${turmaId}/aluno/${pessoa_id}/intro/${introHistoricoId}`,)
            .then((response) => {
                toast('success', 'Adicionado com sucesso');
                const status = response.data.status
                const updatedStudentsArray = updateById(usersRegistration, introHistoricoId, status)
                setUsersRegistration(updatedStudentsArray);
                getClasses()
            })
            .catch((error) => {
                if (error.response) {
                    toast('error', error.response.data.error);
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('error', error.message);
                }
            })
        } catch (err) {
            console.log(err);
            toast('error', 'Ocorreu um erro');
        }
    }, [usersRegistration, setUsersRegistration]);

    const maxLengthText = 20;

    
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        getClasses(page, perPage)
        console.log("fechou modal")
    };

    const handleOpenModalTransferClass  = () => setOpenModalTransferClass(true);
    const handleCloseModalTransferClass = () => setOpenModalTransferClass(false);

    return (
        <>
            <Card sx={{mb: 1}}>
                <Accordion square sx={{borderRadius: 2}}>
                    <AccordionSummary
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    
                        sx={{
                            px: 3,
                            flexDirection: 'row-reverse',

                            '& .MuiAccordionSummary-content': {
                                alignItems: 'center',

                                '&.Mui-expanded': {
                                margin: '12px 0',
                                }
                            },
                            '.MuiAccordionSummary-expandIconWrapper': {
                                borderRadius: 1,
                                border: 1,
                                color: 'text.secondary',
                                borderColor: 'divider',
                                transform: 'none',
                                height: 28,
                                width: 28,
                                alignItems: 'center',
                                justifyContent: 'center',
                                mr: 1,

                                '&.Mui-expanded': {
                                    transform: 'none',
                                    color: 'primary.main',
                                    borderColor: 'primary.main',
                                },

                                '& svg': {
                                    fontSize: '1.25rem',
                                }
                            }
                        }}
                    >
                        <Grid container spacing={3} alignContent={'center'}>
                            <Grid item lg={1} md={2} xs={2} sx={{display: 'none'}}>
                                <Avatar sx={{width: 52, height: 52}} alt={item.nome} src={`${process.env.REACT_APP_API_KEY}/images/${item.imagem_perfil}`} variant="rounded"/>
                            </Grid>
                            <Grid item lg={4} md={4} xs={6}>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={.25}
                                >
                                    Nome
                                </Typography>
                                <Typography variant={"body1"}>{item.User.nome}</Typography>
                            </Grid>

                            <Grid item lg={3} md={3} xs={2} sx={{ display: {xs: 'none', md: 'none', lg: 'block'} }}>
                                <Typography
                                    fontSize={"12px"}
                                    variant={"h6"}
                                    color={"text.secondary"}
                                    mb={.25}
                                >
                                    Curso
                                </Typography>
                                <Typography variant={"body1"}>{item?.Turma?.Curso?.nome}</Typography>
                            </Grid>
                        
                            <Grid item lg={2} md={3} xs={2} sx={{ display: {xs: 'none', md: 'none', lg: 'block'} }}>
                                
                                {item.Turma && (

                                    <Chip 
                                        label={`${item.Turma.horario} - ${item.Turma.dia_da_semana} - ${item.Turma.modo}`} 
                                        color={getChipColor(item.Turma.dia_da_semana)} 
                                    />
                                )}
                            </Grid>
                            <Grid item lg={1} md={1} xs={2}>
                                {
                                    (item.status === null || item.status_curso_introdutorio_historico === null) && !item.User.atende_requisitos
                                    ?
                                        <Tooltip 
                                            title="Aluno não atende pré-requisito"
                                            TransitionComponent={Zoom}
                                            arrow
                                        >
                                            <InfoIcon color='error'/>
                                        </Tooltip>
                                    :
                                    undefined
                                }
                            </Grid>
                            <Grid item lg={1} md={1} xs={2}>
                                {
                                    (item?.Documento_Do_Candidato.length > 0 ) /* && !item.User.atende_requisitos */
                                    ?
                                        <Tooltip 
                                            title="Clique para visualizar documento de compatibilidade de curso"
                                            TransitionComponent={Zoom}
                                            arrow
                                        >
                                            <Button 
                                                sx={{minWidth: 1}} 
                                                disableElevation 
                                                variant={"contained"} 
                                                size={"small"} 
                                                color={"warning"}
                                                onClick={handleOpen}
                                            >
                                                <WarningIcon />
                                            </Button>
                                        </Tooltip>
                                    :
                                        <Tooltip 
                                            title="Clique para adicionar aluno na turma"
                                            TransitionComponent={Zoom}
                                            arrow
                                        >
                                            <Button 
                                                sx={{minWidth: 1}} 
                                                disableElevation 
                                                variant={"contained"} 
                                                size={"small"} 
                                                color={"primary"}
                                                disabled={!hasPermission('Turmas', 'criar')}
                                                onClick={handleOpenModalTransferClass}
                                            >
                                                <PersonAddIcon />
                                            </Button>
                                        </Tooltip>
                                }
                            </Grid>
                            <Grid item lg={1} md={2} xs={4}>
                                <Tooltip 
                                    title="Visualizar"
                                    TransitionComponent={Zoom}
                                    arrow
                                >
                                    <LinkStyle to={`/app/editar-aluno/${item.pessoa_id}`} state={{ backUrl: location.pathname,  disable: true }}>
                                        <VisibilityIcon />
                                    </LinkStyle>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    
                    </AccordionSummary>
                </Accordion>
            </Card>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Div sx={style}>
                        {/* <ModalAuthorizeFile 
                            data={item} 
                            curso_historico_id={item.curso_historico_id} 
                            handleClose={handleClose} 
                            classStudents={classes} 
                            setClassStudents={setClasses}
                            getCourseById={getClasses}
                        /> */}
                        <MatchCandidate
                            item={item}
                            username={item.User.nome}
                            userId={item.User.pessoa_id}
                        />
                    </Div>
                </Fade>
            </Modal>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModalTransferClass}
                onClose={handleCloseModalTransferClass}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModalTransferClass}>
                    <Div sx={style}>
                        <ModalTransferClassEvaluate 
                            data={item} 
                            transferStudentFromCourseIntro={transferStudentFromCourseIntro}
                        />
                    </Div>
                </Fade>
            </Modal>
        </>
    );
};
/* Todo item prop define */
export default EvaluateDocumentsItem;
