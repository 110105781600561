import React, { useState, useCallback, useEffect, useContext } from 'react';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Typography,
  useMediaQuery,
  Box,
  FormLabel,
  RadioGroup,
  Radio,
  Tooltip,
  Checkbox,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import InfoIcon from '@mui/icons-material/Info';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import JumboSelectField from '@jumbo/components/JumboFormik/JumboSelectField';
import InputMask from 'react-input-mask';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { updateInputValue, disableInputValue } from '../../utils/appHelpers';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import axios from 'axios';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { COUNTRIES } from '../../utils/constants/countries';
import Select from 'react-select';
import CircularProgress from '@mui/material/CircularProgress';
import { cpf } from 'cpf-cnpj-validator';
import { PermissionContext } from 'app/contexts/PermissionContext';
import authServices from 'app/servicesTwo/auth-services';
import Div from '@jumbo/shared/Div';
import { set } from 'date-fns';
import FileListSlim from 'app/components/FileUploadSlim/FileListSlim';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80em',
  p: 4,
};

const JoinCoursesIntroForm = () => {
  const { hasPermission } = useContext(PermissionContext);

  const { theme } = useJumboTheme();

  const Swal = useSwalWrapper();

  const lg = useMediaQuery(theme.breakpoints.down('md'));

  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  const initialValues = {
    nome: null,
    nome_social: null,
    data_nascimento: null,
    genero: '',
    cpf: null,
    rg: null,
    telefone: '05511',
    celular: '05511',
    celular_confirmacao: '',
    documento_internacional: '',
    email: null,
    email_confirmacao: '',
    escolaridade: '',
    religiao: '',
    pais: 'BR',
    bairro: '',
    cidade: '',
    estado: '',
    uf: '',
    numero: '',
    complemento: '',
    CEP: '',
    codigo_postal: '',
    senha: '',
    senhaConfirmacao: '',
    /* turmas_id: '', */
    ficha_compativel: null,
  };

  const { id, turmaId } = useParams();
  const [user, setUser] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserVerified, setIsUserVerified] = useState(false);
  const [nomeSocialObrigatorio, setNomeSocialObrigatorio] = useState(true);
  const [classes, setClasses] = useState([]);
  const [classesSelected, setClassesSelected] = useState('');
  const [texts, setTexts] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [userHasPassword, setUserHasPassword] = useState(false);
  const [userHasNotPassword, setUserHasNotPassword] = useState(false);
  const [showVerifyButton, setShowVerifyButton] = useState(true);
  const [errorsFromApi, setErrorsFromApi] = useState('');
  const [tooltipMessage, setTooltipMessage] = useState('');
  const [previewImage, setPreviewImage] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [feespStudent, setFeespStudent] = useState(false);
  const [isNewUser, setIsNewUser] = useState(false);

  const [files, setFiles] = useState([]);
  const [filesSelected, setFilesSelected] = useState([]);
  const [isEditingFile, setIsEditingFile] = useState(false);
  const [textFile, setTextFile] = useState(null);

  const canCreate = hasPermission('Pessoas', 'criar');
  const canEdit = hasPermission('Pessoas', 'editar');
  let { state } = useLocation();
  const navigate = useNavigate();
  const isDisabled = state && state.disable ? true : id ? !canEdit : !canCreate;

  const validationSchema = yup.object().shape(
    {
      email: yup
        .string()
        .email('Endereço de email inválido')
        .nullable()
        .required('Preenchimento obrigatório'),
      email_confirmacao: yup
        .string()
        .oneOf([yup.ref('email'), null], 'Os emails devem ser iguais')
        .required('Preenchimento obrigatório')
        .nullable(),
      cpf: yup
        .string()
        .test({
          message: 'CPF inválido',
          test: (value) => cpf.isValid(value),
        })
        .when(['email', 'rg'], {
          is: (email, rg) => !email && !rg,
          then: yup.string().required('Preenchimento obrigatório').nullable(),
        }),

      nome: !nomeSocialObrigatorio
        ? yup
            .string('Insira seu nome')
            .required('Preenchimento obrigatório')
            .nullable()
        : yup.string().nullable(),

      data_nascimento: yup
        .date()
        .max(new Date(), 'Não é possivel incluir uma data futura')
        .required('Preenchimento obrigatório')
        .nullable(),
      celular: yup
        .string()
        .required('Preenchimento obrigatório')
        .nullable()
        .transform((value) => value?.replace(/[^\d]/g, ''))
        .min(14, 'Preencha o número completo')
        .max(14, 'Preencha o número completo'),
      celular_confirmacao: yup
        .string()
        .required('Preenchimento obrigatório')
        .nullable()
        .transform((value) => value?.replace(/[^\d]/g, ''))
        .test(
          'cell-match',
          'Os números do celular devem ser iguais',
          function (value) {
            return this.parent.celular === value;
          }
        ),
      senha:
        isNewUser || !isUserVerified
          ? yup.string().required('Preenchimento obrigatório')
          : yup.string().nullable(),
      senhaConfirmacao: isNewUser
        ? yup
            .string()
            .required('Preenchimento obrigatório')
            .oneOf([yup.ref('senha')], 'As senhas devem ser iguais')
        : yup.string().nullable(),
    },
    [
      ['email', 'cpf'],
      ['email', 'rg'],
      ['cpf', 'rg'],
    ]
  );

  const toast = (variant, message, timer = 5000, type = false) => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: timer,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: variant,
      title: message,
      showCloseButton: true, // botão de fechar
      closeButtonAriaLabel: 'Fechar',
    });

    //  Redireciona se mensagem de retorno for de sucesso
    /* if(variant == 'success'){
            setTimeout(function(){
                navigate("/app/listar-usuarios")
            }, 2000)
        } */
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirmation = () =>
    setShowPasswordConfirmation((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownPasswordConfirmation = (event) => {
    event.preventDefault();
  };

  const getCourseIntro = useCallback(async () => {
    try {
      await ApiService.get(`/cursos-introdutorios/${id}`)
        .then((response) => {
          console.log(response.data.Turma);
          setClasses(response.data.Turma);
          setTexts(response.data);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (id) getCourseIntro();
  }, []);

  const handleSubmit = async (
    values,
    { setSubmitting, setFieldError, setStatus }
  ) => {
    const hasRequisites = classes[0]?.Curso?.Curso?.length > 0;
    const pessoaId = values.pessoa_id;
    const newValues = {
      ...values,
      turmas_id: turmaId,
    };

    if (hasRequisites && !user.atendeRequisitos) {

      if(filesSelected.length === 0) {
        return toast("warning", "Por favor, envie os documentos")
      }


      let formData = new FormData();
      filesSelected.forEach((file, index) => {
        if (file instanceof File) {
          formData.append('docs', file);
          formData.append(`nome_label_${index}`, file.nome_label);
        }
      });

      await ApiService.post(
        `cursos-introdutorios-com-requisitos/${id}/pessoa`,
        newValues,
        {
          params: { pessoaId: pessoaId },
        }
      )
        .then((response) => {
          toast('success', 'Inscrito com sucesso');
          const curso_introdutorio_historico_id = response?.data?.curso_introdutorio_historico_id
          if (files.length > 0) {
            formData.append('curso_introdutorio_historico_id', curso_introdutorio_historico_id);
            
            ApiService.post(`/documentos-do-candidato/upload`, formData)
              .then(() => {
               

              })
              .catch((error) => {
                if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
              });
          }

          window.location.href = 'http://www.feesp.org.br';
        })
        .catch((error) => {
          const target = error.response.data.error;
          console.log(error.response.data);
          setErrorsFromApi(target);
          toast('error', target);
          //let message = 'Ocorreu um erro'

          if (target.toLowerCase().includes('cpf')) {
            toast('error', 'Digite um CPF único');
            setFieldError('cpf', 'Digite um CPF único');
          } else if (target.toLowerCase().includes('rg')) {
            toast('error', 'Digite um RG único');
            setFieldError('rg', 'Digite um RG único');
          } else if (target.toLowerCase().includes('email')) {
            toast('error', 'Digite um email único');
            setFieldError('email', 'Digite um email único');
          }

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    } else {
      await ApiService.post(`cursos-introdutorios/${id}/pessoa`, newValues, {
        params: { pessoaId: pessoaId },
      })
        .then((response) => {
          toast('success', 'Inscrito com sucesso');
          window.location.href = 'http://www.feesp.org.br';
        })
        .catch((error) => {
          const target = error.response.data.error;
          console.log(error.response.data);
          setErrorsFromApi(target);
          toast('error', target);
          //let message = 'Ocorreu um erro'

          if (target.toLowerCase().includes('cpf')) {
            toast('error', 'Digite um CPF único');
            setFieldError('cpf', 'Digite um CPF único');
          } else if (target.toLowerCase().includes('rg')) {
            toast('error', 'Digite um RG único');
            setFieldError('rg', 'Digite um RG único');
          } else if (target.toLowerCase().includes('email')) {
            toast('error', 'Digite um email único');
            setFieldError('email', 'Digite um email único');
          }

          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
    }

    setSubmitting(false);
  };

  const makePostRequest = () => {
    const fromLocalStorage = localStorage.getItem('userPendent');
    const parseItems = JSON.parse(fromLocalStorage);
    const valuesToSave = { ...parseItems };
    delete valuesToSave.turmas_id;

    const pessoaId = parseItems.pessoa_id;
    ApiService.post(`cursos-introdutorios/${id}/pessoa`, valuesToSave, {
      params: { pessoaId: pessoaId },
    })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        const target = error.response.data.error;
        console.log(error.response.data);
        setErrorsFromApi(target);
        toast('error', target);
        //let message = 'Ocorreu um erro'

        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('error', error.message);
        }
      });
  };

  /* if (isUserVerified) {

        setInterval(() => {
            makePostRequest()
        }, 30000);
    } */

  const checkCep = async (e, setFieldValue) => {
    setIsLoading(true);
    const { value } = e.target;

    const cep = value.replace(/\D/g, '');
    await axios
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => {
        console.log(res.data);
        setFieldValue('endereco', res.data.logradouro);
        // setFieldValue('complemento', res.data.complemento)
        setFieldValue('bairro', res.data.bairro);
        setFieldValue('cidade', res.data.localidade);
        setFieldValue('uf', res.data.uf);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const errorsHandler = (errors) => {
    if (Object.keys(errors).length != 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      if (errors.cpf) {
        //toast('error', errors.cpf)
      } else if (errors.data_nascimento) {
        //toast('error', errors.data_nascimento)
      } else if (errors.email) {
        // toast('error', errors.email)
      } else if (errors.email_confirmacao) {
        // toast('error', errors.email_confirmacao)
      } else if (errors.celular) {
        // toast('error', errors.celular)
      } else if (errors.celular_confirmacao) {
        // toast('error', errors.celular_confirmacao)
      } else {
        //toast('error', errors.nome)
      }
    } else {
      return null;
    }
  };

  const verifyValidations = (values) => {
    if (values.nome_social !== null && values.nome_social !== '') {
      setNomeSocialObrigatorio(true);
    } else {
      setNomeSocialObrigatorio(false);
    }
  };

  function isValidCpf(value) {
    // Remove formatting and checks if length is exactly 11 (valid CPF length without formatting)
    return value && value.replace(/\D/g, '').length === 11;
  }

  const onSignIn = async (cpf, senha) => {
    await authServices
      .verifySenha({ cpf, senha, id, turmaId })
      .then((data) => {
        const { senha, ...userWithoutPassword } = data.user;
        const turmas_id = data.getCursoIntrodutorioHistorico?.turmas_id;

        setUser(userWithoutPassword);

        if (
          userWithoutPassword.pais == null ||
          userWithoutPassword.pais == '' ||
          userWithoutPassword.pais == 'brasil'
        ) {
          setUser({ ...userWithoutPassword, pais: 'BR' });
        }
        if (turmas_id) {
          setUser((prev) => ({ ...prev, turmas_id }));
        }

        setUser({ ...userWithoutPassword, atendeRequisitos: data.atende_requisitos });

        /* if (data.statusDoCurso) {
                    Swal.fire({
                        title: 'Identificamos que você participou do curso anterior, mas não obteve nota suficiente para aprovação. Gostaria de se matricular novamente neste curso para melhorar sua nota e alcançar a aprovação?',
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Sim!',
                        cancelButtonText: 'Não!',
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            return setIsUserVerified(true);
                        } else {
                            return setIsUserVerified(false);
                        }
                    });
                    return;
                }

                Swal.close(); */
        setIsUserVerified(true);
      })
      .catch((err) => {
        if (err.response.data.error) {
          toast('error', err.response.data.error);
        } else {
          toast('error', err.response.data);
        }
      });
  };

  const handleChangeImage = (e) => {
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
    setUserImage(e.target.files[0]);
  };

  const handleRemoveImage = () => {
    setUser((prevValues) => ({
      ...prevValues,
      ficha_compativel: null,
    }));
    setPreviewImage(null);
    setUserImage(null);
  };

  const uploadHandler = (event) => {
    const file = event.target.files[0];

    if (!file) return;

    setFiles([...files, file]);
    setFilesSelected([...filesSelected, file]);
  };

  const removeFile = (index) => {
    setFiles(files.filter((file, i) => i !== index));
  };

  const handleSaveClick = (index) => {
    setIsEditingFile(false);
    files.map((file, i) =>
      i == index ? (file.nome_label = textFile) : file.nome_label
    );
    setTextFile('');
  };

  const handleEditClick = (index) => {
    setIsEditingFile({ active: true, index: index });
    setTextFile(
      files
        .filter(
          (file, i) =>
            i === index &&
            (file.nome_label || file.nome || file instanceof File)
        )
        .map((file) => {
          if (file instanceof File && file.nome_label) {
            return file.nome_label;
          }
          return file.nome_label || file.nome || file.name;
        })
    );
  };

  const handleInputChangeFile = (e) => {
    setTextFile(e.target.value);
  };

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      <Paper sx={{ p: '2rem', background: '#f9f9f9' }}>
        {state && (
          <Button
            color='success'
            type='submit'
            variant='contained'
            size='large'
            sx={{ maxWidth: { md: '200px', mt: '1rem' } }}
            onClick={() => navigate(state.backUrl)}
          >
            Voltar
          </Button>
        )}
        <Box textAlign='center'>
          <div dangerouslySetInnerHTML={{ __html: texts?.titulo }} />
          <div dangerouslySetInnerHTML={{ __html: texts?.subtitulo }} />
          <div dangerouslySetInnerHTML={{ __html: texts?.descricao }} />
        </Box>
        <Formik
          initialValues={user}
          // validationSchema={null}
          validationSchema={validationSchema}
          enableReinitialize
          validateOnChange={false}
          //validateOnBlur={true}
          onSubmit={handleSubmit}
        >
          {({
            values,
            isSubmitting,
            handleChange,
            setFieldValue,
            touched,
            errors,
            setTouched,
            setFieldTouched,
          }) => (
            <Form style={{ width: '100%' }} noValidate autoComplete='off'>
              <Grid container alignContent={'center'}>
                <Grid
                  container
                  spacing={3}
                  alignContent={'center'}
                  sx={{
                    mt: '0.8rem',
                    background: '#EFF0F2',
                    marginLeft: 0,
                    padding: '0 24px 24px 0',
                  }}
                >
                  {!isUserVerified && !isNewUser && (
                    <Grid item xs={12}>
                      <Typography variant='h6'>
                        Se você já tem um cadastro, por favor, entre com seu CPF
                        e senha. Caso contrário, você pode&nbsp;
                        <Tooltip title='Clique para criar uma nova conta' arrow>
                          <Link onClick={() => {
                            setIsNewUser(true)
                            getCourseIntro()
                          }}>
                            criar uma conta
                          </Link>
                        </Tooltip>
                        .
                      </Typography>
                    </Grid>
                  )}
                  {!isNewUser && (
                    <>
                      <Grid item lg={4} xs={12}>
                        <InputMask
                          mask='999.999.999-99'
                          value={updateInputValue(user, values, 'cpf')}
                          onChange={handleChange}
                          disabledUnderline={isUserVerified}
                          disabled={isUserVerified}
                        >
                          {(inputProps) => (
                            <JumboTextField
                              {...inputProps}
                              id='cpf'
                              fullWidth
                              name='cpf'
                              label='CPF'
                              type='text'
                              sx={{
                                background: '#ffffff',
                              }}
                              disabled={inputProps.disabledUnderline}
                            />
                          )}
                        </InputMask>
                      </Grid>

                      <Grid item lg={4} xs={12}>
                        <JumboTextField
                          fullWidth
                          id='senha'
                          type={showPassword ? 'text' : 'password'}
                          name='senha'
                          value={values.senha}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={() =>
                                    setShowPassword((show) => !show)
                                  }
                                  onMouseDown={(e) => e.preventDefault()}
                                  edge='end'
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            shrink: isUserVerified ? true : undefined,
                          }}
                          sx={{
                            background: '#ffffff',
                          }}
                          label='Senha'
                          onKeyUp={(event) => {
                            if (event.key === 'Enter') {
                              onSignIn(values.cpf, values.senha);
                            }
                          }}
                          disabled={isUserVerified}
                        />
                        {!isUserVerified && (
                          <Box padding={1}>
                            <Link to='/esqueci-senha' target='_blank'>
                              Esqueceu a senha? Clique aqui
                            </Link>
                          </Box>
                        )}
                      </Grid>

                      <Grid
                        item
                        lg={4}
                        xs={12}
                        textAlign={{
                          xs: 'center',
                          lg: 'initial',
                        }}
                      >
                        <LoadingButton
                          color='success'
                          variant='contained'
                          size='large'
                          onClick={() => onSignIn(values.cpf, values.senha)}
                          sx={{
                            maxWidth: {
                              md: '200px',
                              mt: '1rem',
                            },
                          }}
                          disabled={!isValidCpf(values.cpf) || !values.senha}
                        >
                          Enviar
                        </LoadingButton>
                      </Grid>
                    </>
                  )}

                  {(isUserVerified || isNewUser) && (
                    <>
                      <Grid item lg={6} xs={12}>
                        <JumboTextField
                          fullWidth
                          name='nome'
                          label='Nome'
                          value={updateInputValue(user, values, 'nome')}
                          sx={{
                            background: '#ffffff',
                          }}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <JumboTextField
                          fullWidth
                          name='nome_social'
                          label='Nome Social'
                          value={updateInputValue(user, values, 'nome_social')}
                          sx={{
                            background: '#ffffff',
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <JumboTextField
                          fullWidth
                          name='data_nascimento'
                          type='date'
                          label='Data de Nascimento'
                          value={updateInputValue(
                            user,
                            values,
                            'data_nascimento'
                          )}
                          sx={{
                            background: '#ffffff',
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>

                      <Grid item lg={4} xs={12}>
                        <JumboTextField
                          fullWidth
                          name='rg'
                          label='RG'
                          value={updateInputValue(user, values, 'rg')}
                          sx={{
                            background: '#ffffff',
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} xs={12}>
                        <InputMask
                          mask='999.999.999-99'
                          value={updateInputValue(user, values, 'cpf')}
                          onChange={handleChange}
                          disabledUnderline={isUserVerified}
                          disabled={isUserVerified}
                        >
                          {(inputProps) => (
                            <JumboTextField
                              {...inputProps}
                              id='cpf'
                              fullWidth
                              name='cpf'
                              label='CPF'
                              type='text'
                              sx={{
                                background: '#ffffff',
                              }}
                              disabled={inputProps.disabledUnderline}
                            />
                          )}
                        </InputMask>
                      </Grid>

                      <Grid item lg={6} xs={12}>
                        <InputMask
                          mask='+999(99)99999-9999'
                          maskPlaceholder={null}
                          error={touched.celular ? errors.celular : undefined}
                          touched={touched.celular}
                          value={updateInputValue(user, values, 'celular')}
                          onChange={handleChange}
                          disabled={false}
                          disabledUnderline={false}
                        >
                          {(inputProps) => (
                            <JumboTextField
                              {...inputProps}
                              fullWidth
                              name='celular'
                              label='Celular (DDI, DDD e número)'
                              sx={{
                                background: '#ffffff',
                              }}
                              disabled={inputProps.disabledUnderline}
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <InputMask
                          mask='+999(99)99999-9999'
                          maskPlaceholder={null}
                          value={updateInputValue(
                            user,
                            values,
                            'celular_confirmacao'
                          )}
                          onChange={handleChange}
                          onPaste={(e) => e.preventDefault()}
                          error={
                            touched.celular_confirmacao
                              ? errors.celular_confirmacao
                              : undefined
                          }
                          touched={touched.celular_confirmacao}
                          // onBlur={() => setTouched({'celular_confirmacao': true})}
                          onBlur={() =>
                            setFieldTouched('celular_confirmacao', true)
                          }
                          disabled={false}
                          disabledUnderline={false}
                        >
                          {(inputProps) => (
                            <JumboTextField
                              {...inputProps}
                              fullWidth
                              name='celular_confirmacao'
                              label='Confirmar celular (DDI, DDD e número)'
                              sx={{
                                background: '#ffffff',
                              }}
                              disabled={inputProps.disabledUnderline}
                            />
                          )}
                        </InputMask>
                      </Grid>

                      <Grid item lg={6} xs={12}>
                        <JumboTextField
                          fullWidth
                          name='email'
                          label='Email'
                          type='email'
                          value={updateInputValue(user, values, 'email')}
                          sx={{
                            background: '#ffffff',
                          }}
                          error={touched.email ? errors.email : undefined}
                          touched={+touched.email}
                        />
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <JumboTextField
                          fullWidth
                          name='email_confirmacao'
                          label='Confirmar email'
                          type='email'
                          value={updateInputValue(
                            user,
                            values,
                            'email_confirmacao'
                          )}
                          sx={{
                            background: '#ffffff',
                          }}
                          onPaste={(e) => e.preventDefault()}
                          onChange={handleChange}
                          error={
                            touched.email_confirmacao
                              ? errors.email_confirmacao
                              : undefined
                          }
                          touched={+touched.email_confirmacao}
                        />
                      </Grid>
                      {isNewUser && (
                        <>
                          <Grid item lg={5} xs={12}>
                            <JumboTextField
                              fullWidth
                              id='senha'
                              type={showPassword ? 'text' : 'password'}
                              name='senha'
                              value={values.senha}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton
                                      aria-label='toggle password visibility'
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge='end'
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                background: '#ffffff',
                              }}
                              label='Senha'
                              onBlur={() => setFieldTouched('senha', true)}
                            />
                          </Grid>
                          <Grid item lg={5} xs={12}>
                            <JumboTextField
                              fullWidth
                              id='senhaConfirmacao'
                              type={
                                showPasswordConfirmation ? 'text' : 'password'
                              }
                              name='senhaConfirmacao'
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton
                                      aria-label='toggle password visibility'
                                      onClick={
                                        handleClickShowPasswordConfirmation
                                      }
                                      onMouseDown={
                                        handleMouseDownPasswordConfirmation
                                      }
                                      edge='end'
                                    >
                                      {showPasswordConfirmation ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                background: '#ffffff',
                              }}
                              label='Confirmar nova senha'
                              onBlur={() =>
                                setFieldTouched('senhaConfirmacao', true)
                              }
                            />
                          </Grid>
                        </>
                      )}
                      {lg ? (
                        <>
                          <Grid item lg={8} xs={12}>
                            <Typography variant='h6' component='h6'>
                              Escolha no campo de seleção abaixo o País em que
                              você mora atualmente
                            </Typography>
                          </Grid>
                          <Grid item lg={4} xs={12}>
                            <FormControl fullWidth>
                              <Select
                                options={COUNTRIES}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                onChange={(pais) =>
                                  setFieldValue('pais', pais.value)
                                }
                                value={COUNTRIES.filter(
                                  (option) => option.value === values.pais
                                )}
                                placeholder='País'
                              />
                            </FormControl>
                          </Grid>

                          {values.pais === 'BR' ? (
                            <>
                              <Grid item lg={2} xs={10}>
                                <InputMask
                                  mask='99999-999'
                                  value={updateInputValue(user, values, 'CEP')}
                                  onChange={handleChange}
                                  onBlur={(e) => checkCep(e, setFieldValue)}
                                  disabled={false}
                                  disabledUnderline={false}
                                >
                                  {(inputProps) => (
                                    <JumboTextField
                                      {...inputProps}
                                      fullWidth
                                      name='CEP'
                                      label='CEP'
                                      sx={{
                                        background: '#ffffff',
                                      }}
                                      disabled={inputProps.disabledUnderline}
                                    />
                                  )}
                                </InputMask>
                              </Grid>
                              <Grid item lg={1} xs={1}>
                                {isLoading && (
                                  <CircularProgress
                                    style={{
                                      alignSelf: 'center',
                                    }}
                                  />
                                )}
                              </Grid>

                              <Grid item lg={2} xs={6}>
                                <FormControl fullWidth>
                                  <InputLabel id='uf'>UF</InputLabel>
                                  <JumboSelectField
                                    labelId='uf'
                                    id='uf'
                                    name='uf'
                                    value={updateInputValue(user, values, 'uf')}
                                    label='Uf'
                                    sx={{
                                      background: '#ffffff',
                                    }}
                                  >
                                    <MenuItem value={'AC'}>Acre</MenuItem>
                                    <MenuItem value={'AL'}>Alagoas</MenuItem>
                                    <MenuItem value={'AP'}>Amapá</MenuItem>
                                    <MenuItem value={'AM'}>Amazonas</MenuItem>
                                    <MenuItem value={'BA'}>Bahia</MenuItem>
                                    <MenuItem value={'CE'}>Ceará</MenuItem>
                                    <MenuItem value={'DF'}>
                                      Distrito Federal
                                    </MenuItem>
                                    <MenuItem value={'ES'}>
                                      Espírito Santo
                                    </MenuItem>
                                    <MenuItem value={'GO'}>Goiás</MenuItem>
                                    <MenuItem value={'MA'}>Maranhão</MenuItem>
                                    <MenuItem value={'MT'}>
                                      Mato Grosso
                                    </MenuItem>
                                    <MenuItem value={'MS'}>
                                      Mato Grosso do Sul
                                    </MenuItem>
                                    <MenuItem value={'MG'}>
                                      Minas Gerais
                                    </MenuItem>
                                    <MenuItem value={'PA'}>Pará</MenuItem>
                                    <MenuItem value={'PB'}>Paraíba</MenuItem>
                                    <MenuItem value={'PR'}>Paraná</MenuItem>
                                    <MenuItem value={'PE'}>Pernambuco</MenuItem>
                                    <MenuItem value={'PI'}>Piauí</MenuItem>
                                    <MenuItem value={'RJ'}>
                                      Rio de Janeiro
                                    </MenuItem>
                                    <MenuItem value={'RN'}>
                                      Rio Grande do Norte
                                    </MenuItem>
                                    <MenuItem value={'RS'}>
                                      Rio Grande do Sul
                                    </MenuItem>
                                    <MenuItem value={'RO'}>Rondônia</MenuItem>
                                    <MenuItem value={'RR'}>Roraima</MenuItem>
                                    <MenuItem value={'SC'}>
                                      Santa Catarina
                                    </MenuItem>
                                    <MenuItem value={'SP'}>São Paulo</MenuItem>
                                    <MenuItem value={'SE'}>Sergipe</MenuItem>
                                    <MenuItem value={'TO'}>Tocantins</MenuItem>
                                  </JumboSelectField>
                                </FormControl>
                              </Grid>
                              <Grid item lg={4} xs={6}>
                                <JumboTextField
                                  fullWidth
                                  name='cidade'
                                  label='Cidade'
                                  value={updateInputValue(
                                    user,
                                    values,
                                    'cidade'
                                  )}
                                  sx={{
                                    background: '#ffffff',
                                  }}
                                />
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={12}>
                                <JumboTextField
                                  fullWidth
                                  name='endereco'
                                  label='Endereço'
                                  value={updateInputValue(
                                    user,
                                    values,
                                    'endereco'
                                  )}
                                  sx={{
                                    background: '#ffffff',
                                  }}
                                />
                              </Grid>
                              <Grid item lg={4} xs={6}>
                                <JumboTextField
                                  fullWidth
                                  name='codigo_postal'
                                  label='Codigo postal'
                                  sx={{
                                    background: '#ffffff',
                                  }}
                                  value={updateInputValue(
                                    user,
                                    values,
                                    'codigo_postal'
                                  )}
                                />
                              </Grid>

                              <Grid item lg={4} xs={6}>
                                <JumboTextField
                                  fullWidth
                                  name='estado'
                                  label='Estado'
                                  value={updateInputValue(
                                    user,
                                    values,
                                    'estado'
                                  )}
                                  sx={{
                                    background: '#ffffff',
                                  }}
                                />
                              </Grid>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <Grid item lg={4} xs={6}>
                            <FormControl fullWidth>
                              <Select
                                options={COUNTRIES}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                onChange={(pais) =>
                                  setFieldValue('pais', pais.value)
                                }
                                value={COUNTRIES.filter(
                                  (option) => option.value === values.pais
                                )}
                                placeholder='País'
                              />
                            </FormControl>
                          </Grid>
                          <Grid item lg={8} xs={6}>
                            <Typography variant='h6' component='h6'>
                              Escolha no campo de seleção ao lado o País em que
                              você mora atualmente
                            </Typography>
                          </Grid>

                          {values.pais === 'BR' ? (
                            <>
                              <Grid item lg={2} xs={6}>
                                <InputMask
                                  mask='99999-999'
                                  value={updateInputValue(user, values, 'CEP')}
                                  onChange={handleChange}
                                  onBlur={(e) => checkCep(e, setFieldValue)}
                                  disabled={false}
                                  disabledUnderline={false}
                                >
                                  {(inputProps) => (
                                    <JumboTextField
                                      {...inputProps}
                                      fullWidth
                                      name='CEP'
                                      label='CEP'
                                      sx={{
                                        background: '#ffffff',
                                      }}
                                      disabled={inputProps.disabledUnderline}
                                    />
                                  )}
                                </InputMask>
                              </Grid>
                              <Grid item lg={1} xs={1}>
                                {isLoading && (
                                  <CircularProgress
                                    style={{
                                      alignSelf: 'center',
                                    }}
                                  />
                                )}
                              </Grid>

                              <Grid item lg={2} xs={6}>
                                <FormControl fullWidth>
                                  <InputLabel id='uf'>UF</InputLabel>
                                  <JumboSelectField
                                    labelId='uf'
                                    id='uf'
                                    name='uf'
                                    value={updateInputValue(user, values, 'uf')}
                                    label='Uf'
                                    sx={{
                                      background: '#ffffff',
                                    }}
                                  >
                                    <MenuItem value={'AC'}>Acre</MenuItem>
                                    <MenuItem value={'AL'}>Alagoas</MenuItem>
                                    <MenuItem value={'AP'}>Amapá</MenuItem>
                                    <MenuItem value={'AM'}>Amazonas</MenuItem>
                                    <MenuItem value={'BA'}>Bahia</MenuItem>
                                    <MenuItem value={'CE'}>Ceará</MenuItem>
                                    <MenuItem value={'DF'}>
                                      Distrito Federal
                                    </MenuItem>
                                    <MenuItem value={'ES'}>
                                      Espírito Santo
                                    </MenuItem>
                                    <MenuItem value={'GO'}>Goiás</MenuItem>
                                    <MenuItem value={'MA'}>Maranhão</MenuItem>
                                    <MenuItem value={'MT'}>
                                      Mato Grosso
                                    </MenuItem>
                                    <MenuItem value={'MS'}>
                                      Mato Grosso do Sul
                                    </MenuItem>
                                    <MenuItem value={'MG'}>
                                      Minas Gerais
                                    </MenuItem>
                                    <MenuItem value={'PA'}>Pará</MenuItem>
                                    <MenuItem value={'PB'}>Paraíba</MenuItem>
                                    <MenuItem value={'PR'}>Paraná</MenuItem>
                                    <MenuItem value={'PE'}>Pernambuco</MenuItem>
                                    <MenuItem value={'PI'}>Piauí</MenuItem>
                                    <MenuItem value={'RJ'}>
                                      Rio de Janeiro
                                    </MenuItem>
                                    <MenuItem value={'RN'}>
                                      Rio Grande do Norte
                                    </MenuItem>
                                    <MenuItem value={'RS'}>
                                      Rio Grande do Sul
                                    </MenuItem>
                                    <MenuItem value={'RO'}>Rondônia</MenuItem>
                                    <MenuItem value={'RR'}>Roraima</MenuItem>
                                    <MenuItem value={'SC'}>
                                      Santa Catarina
                                    </MenuItem>
                                    <MenuItem value={'SP'}>São Paulo</MenuItem>
                                    <MenuItem value={'SE'}>Sergipe</MenuItem>
                                    <MenuItem value={'TO'}>Tocantins</MenuItem>
                                  </JumboSelectField>
                                </FormControl>
                              </Grid>
                              <Grid item lg={4} xs={6}>
                                <JumboTextField
                                  fullWidth
                                  name='cidade'
                                  label='Cidade'
                                  value={updateInputValue(
                                    user,
                                    values,
                                    'cidade'
                                  )}
                                  sx={{
                                    background: '#ffffff',
                                  }}
                                />
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={12}>
                                <JumboTextField
                                  fullWidth
                                  name='endereco'
                                  label='Endereço'
                                  value={updateInputValue(
                                    user,
                                    values,
                                    'endereco'
                                  )}
                                  sx={{
                                    background: '#ffffff',
                                  }}
                                />
                              </Grid>
                              <Grid item lg={4} xs={6}>
                                <JumboTextField
                                  fullWidth
                                  name='codigo_postal'
                                  label='Codigo postal'
                                  sx={{
                                    background: '#ffffff',
                                  }}
                                  value={updateInputValue(
                                    user,
                                    values,
                                    'codigo_postal'
                                  )}
                                />
                              </Grid>

                              <Grid item lg={4} xs={6}>
                                <JumboTextField
                                  fullWidth
                                  name='estado'
                                  label='Estado'
                                  value={updateInputValue(
                                    user,
                                    values,
                                    'estado'
                                  )}
                                  sx={{
                                    background: '#ffffff',
                                  }}
                                />
                              </Grid>
                            </>
                          )}
                        </>
                      )}

                      <Grid item lg={4} xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id='escolaridade'>
                            Nível de escolaridade
                          </InputLabel>
                          <JumboSelectField
                            labelId='escolaridade'
                            id='escolaridade'
                            name='escolaridade'
                            value={updateInputValue(
                              user,
                              values,
                              'escolaridade'
                            )}
                            label='Nível de escolaridade'
                            sx={{
                              background: '#ffffff',
                            }}
                          >
                            <MenuItem value={'nao_estudei'}>
                              Não estudei
                            </MenuItem>
                            <MenuItem value={'primeiro_grau'}>
                              Primeiro grau
                            </MenuItem>
                            <MenuItem value={'segundo_grau'}>
                              Segundo grau
                            </MenuItem>
                            <MenuItem value={'superior'}>Superior</MenuItem>
                            <MenuItem value={'pos_graduado'}>
                              Pós graduado
                            </MenuItem>
                            <MenuItem value={'mestrado'}>Mestrado</MenuItem>
                            <MenuItem value={'doutorado'}>Doutorado</MenuItem>
                            <MenuItem value={'pos_doutorado'}>
                              Pós doutorado
                            </MenuItem>
                          </JumboSelectField>
                        </FormControl>
                      </Grid>

                      <Grid item lg={4} xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id='religiao'>
                            Religião de origem
                          </InputLabel>
                          <JumboSelectField
                            labelId='religiao'
                            id='religiao'
                            name='religiao'
                            label='Religião de origem'
                            value={updateInputValue(user, values, 'religiao')}
                            sx={{
                              background: '#ffffff',
                            }}
                          >
                            <MenuItem value={'budismo'}>Budismo</MenuItem>
                            <MenuItem value={'catolica'}>Católica</MenuItem>
                            <MenuItem value={'espiritismo'}>
                              Espiritismo
                            </MenuItem>
                            <MenuItem value={'matriz_africana'}>
                              Matriz africana
                            </MenuItem>
                            <MenuItem value={'protestante'}>
                              Protestante
                            </MenuItem>
                            <MenuItem value={'umbanda'}>Umbanda</MenuItem>
                            <MenuItem value={'outra'}>Outra</MenuItem>
                          </JumboSelectField>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        {classes[0]?.Curso?.Curso?.length > 0 ? (
                          <>
                            <Typography variant='h5' component='div'>
                              Para se inscrever neste curso, é necessário ter
                              concluído com sucesso o(s) seguinte(s) curso(s)
                              pré-requisito(s):
                            </Typography>
                            <List>
                              {classes[0]?.Curso?.Curso?.map((requirement) => (
                                <ListItem>
                                  <ListItemText
                                    primary={requirement.pre_requisito.nome}
                                    sx={{
                                      color: '#0092D1',
                                    }}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </>
                        ) : null}
                      </Grid>

                      {classes[0]?.Curso?.Curso?.length > 0 && !user.atendeRequisitos ? (
                        <Grid item xs={12}>
                          <FormControlLabel
                            //name={updateInputValue(user, values, 'aluno_feesp')}
                            control={
                              <Checkbox
                                name='aluno_feesp'
                                checked={feespStudent}
                              />
                            }
                            label='Não concluiu nenhum curso na FEESP?'
                            labelPlacement='end'
                            onChange={() => setFeespStudent(!feespStudent)}
                          />
                        </Grid>
                      ) : null}

                      {feespStudent ? (
                        <>
                          <Grid item xs={12}>
                            <Typography variant='h5'>
                              Por favor, anexe documentos que comprovem os
                              cursos que você já concluiu. Este documento será
                              avaliado posteriormente para ajudar-nos a validar
                              seu histórico educacional.
                            </Typography>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <Button
                                variant='contained'
                                component='label'
                                color='info'
                                sx={{
                                  height: '53.13px',
                                  fontSize: {
                                    xs: '0.7rem',
                                  },
                                  textAlign: 'center',
                                }}
                              >
                                Adicionar Declarações/Comprovantes
                                <input
                                  type='file'
                                  hidden
                                  name='imagem_area'
                                  onChange={uploadHandler}
                                />
                              </Button>
                            </Grid>
                            <Grid item xs={12}>
                              <FileListSlim
                                files={files}
                                removeFile={removeFile}
                                isEditingFile={isEditingFile}
                                textFile={textFile}
                                handleInputChangeFile={handleInputChangeFile}
                                handleSaveClick={handleSaveClick}
                                handleEditClick={handleEditClick}
                                setIsEditingFile={setIsEditingFile}
                              />
                            </Grid>
                          </Grid>
                        </>
                      ) : undefined}

                      <Grid item xs={12}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: texts?.texto_complementar,
                          }}
                        />
                        {/* <FormControl>
                                                    <FormLabel id='demo-radio-buttons-group-label'>
                                                        Escolha seu horário
                                                    </FormLabel>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby='demo-radio-buttons-group-label'
                                                        name='turmas_id'
                                                        value={updateInputValue(
                                                            user,
                                                            values,
                                                            'turmas_id'
                                                        )}
                                                        onChange={handleChange}
                                                    >
                                                        {classes.map(
                                                            (classe) => (
                                                                <FormControlLabel
                                                                    value={
                                                                        classe.turmas_id
                                                                    }
                                                                    control={
                                                                        <Radio
                                                                            //checked={true}
                                                                            size='small'
                                                                        />
                                                                    }
                                                                    label={`${classe.dia_da_semana} - ${classe.horario}`}
                                                                />
                                                            )
                                                        )}
                                                    </RadioGroup>
                                                </FormControl> */}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{
                          textAlign: 'center',
                          mt: '1rem',
                        }}
                      >
                        <LoadingButton
                          color='success'
                          type='submit'
                          variant='contained'
                          size='large'
                          sx={{
                            maxWidth: {
                              md: '200px',
                              mt: '1rem',
                            },
                          }}
                          loading={isSubmitting}
                        >
                          Enviar
                        </LoadingButton>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>

              {isSubmitting /* && Object.keys(errors).length !== 0 */
                ? errorsHandler(errors)
                : null}
              {console.log(errors)}

              {verifyValidations(values)}
              {localStorage.setItem('userPendent', JSON.stringify(values))}
            </Form>
          )}
        </Formik>
      </Paper>
    </JumboContentLayout>
  );
};

export default JoinCoursesIntroForm;
