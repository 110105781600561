import React, { useState, useCallback, useEffect, Fragment, useContext } from 'react'
import JumboContentLayout from "@jumbo/components/JumboContentLayout";
import PageHeader from "../../../layouts/shared/headers/PageHeader/PageHeader";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { Grid, Paper, Stack, useMediaQuery, Typography, Box, Pagination} from "@mui/material";
import { LoadingButton } from '@mui/lab';

import { ApiService } from 'app/servicesTwo/ApiService';
import EvaluateDocumentsItem from './EvaluateDocumentsItem';
import JumboSearch from "@jumbo/components/JumboSearch";
import { Link, useLocation } from 'react-router-dom';
import { PermissionContext } from 'app/contexts/PermissionContext';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';


const EvaluateDocumentsList = () => {  
    const {theme} = useJumboTheme();
    const location = useLocation();
    const Swal = useSwalWrapper();
    
    const lg = useMediaQuery(theme.breakpoints.down('lg'));
    const layoutOptions = React.useMemo(() => ({
        sidebar: {
            sx: {
                [theme.breakpoints.up('lg')]: {
                    position: 'sticky',
                    zIndex: 5,
                    top: 96,
                    minHeight: 'auto',
                },
                [theme.breakpoints.down('lg')]: {
                    display: 'none',
                },
            }
        },
        wrapper: {
            sx: {
                alignItems: 'flex-start',
            }
        },
    }), [theme]);

    const [classes, setClasses] = useState(null)
    const [searchTerm, setSearchTerm] = useState(null)
    const [loading, setLoading] = useState(false)

    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [count, setCount] = useState(null);

    const { hasPermission } = useContext(PermissionContext);

    useEffect(() => {
        if(searchTerm == undefined) return;

        if(searchTerm == '') {
            setLoading(true)
            searchClass(searchTerm)
            setLoading(false)
        }

        const delayDebounceFn = setTimeout(() => {
          setLoading(true)
          searchClass(searchTerm)
          setLoading(false)
        }, 1500)

        
        return () => clearTimeout(delayDebounceFn)
      }, [searchTerm])

    const getClasses = useCallback(async (page, perPage) => {
        try {
            ApiService.get('/aguardando-avaliacao-documentos', {params: {page, perPage}})
            .then((response) => {
              console.log(response.data)
              setClasses(response.data.allUsers);
              setCount(response.data.count)
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
      }, []);

      const searchClass = useCallback(async (e) => {
        console.log('pesquisar', e)
        try {
            ApiService.get('/aguardando-avaliacao-documentos', {params: {search: e}})
            .then((response) => {
              setClasses(response.data);
            })
            .catch((error) => {
              if (error.response) {
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  console.log(error.request);
                } else {
                  console.log('error', error.message);
                }
            })
        } catch (err) {
          console.log(err);
        }
      }, []);

      useEffect(() => {
        getClasses(page, perPage);
      }, [page, count]);

      useEffect(() => {
        console.log('atualizou', classes)
      }, [setClasses, classes]);

      useEffect(() => {
        Swal.close();
      }, [location.pathname, Swal]);

  return (
    <JumboContentLayout
        header={
            <PageHeader
                title={"Listagem de Pessoas Aguardando Aprovação"}
            />
        }
       
        layoutOptions={layoutOptions}
    >
        {
            lg && (
                <Stack spacing={2} direction={"row"} sx={{mb: 3, mt: -2}}>
                </Stack>
            )
        }
        <Paper sx={{p: '40px'}}>
            <Fragment>
                
                {
                    
                    classes && classes.map((user, key) => (
                        <EvaluateDocumentsItem 
                          item={user} 
                          setClasses={(data) => setClasses([...data])} 
                          classes={classes} 
                          key={key}
                          getClasses={getClasses}
                          page={page}
                          perPage={perPage}
                        />

                    ))
                }
                {
                    classes && classes.length == 0 && <Typography>Sem resultados correspondentes</Typography>
                }
                <Box sx={{ mt: 2 }}>
                  <Pagination
                      color="primary"
                      count={count}
                      page={page}
                      onChange={(event, value) => setPage(value)}
                  />
              </Box>
            </Fragment>
        </Paper>
    </JumboContentLayout>
  )
}

export default EvaluateDocumentsList