import React from 'react';
import {useNavigate, matchPath} from "react-router-dom";
import {routesForAuthenticatedOnly, routesForNotAuthenticatedOnly, routesForPublic} from "../../../app/routes";
import useRoutePathMatch from "@jumbo/hooks/useRoutePathMatch";
import {removeToken, storeToken} from "./authHelpers";
import {config} from "../../../app/config/main";
import {AuthContext} from "@jumbo/components/JumboAuthProvider/JumboAuthContext";


const storedToken = localStorage.getItem("token");
const storedUserId = localStorage.getItem("storedUserId");
const storedUsername = localStorage.getItem("storedUsername");
const storedUserEmail = localStorage.getItem("storedUserEmail");
const storedUserImage = localStorage.getItem("storedUserImage");
const storedPermissions = JSON.parse(localStorage.getItem("storedPermissions"))
//let firstTimePageLoad = true;
  
const init = () => {
    //let storedUserLogged = null;
    if (!config?.authSetting) {
        throw Error(`You are using JumboAuthProvider but you haven't setup authSetting inside /src/app/config/main.js's config object`);
    }

    if (storedToken) {
        storeToken(storedToken, storedUserId, storedUsername, storedUserEmail, storedUserImage, storedPermissions); // also sets axios header
    }

    return {
        authToken: storedToken ?? null,
        authUserId: storedUserId,
        authUser: storedUsername ?? null,
        authEmail: storedUserEmail,
        authUserImage: storedUserImage,
        userPermissions: storedPermissions,
        isLoading: false,
        fallbackPath: config.authSetting.fallbackPath,
    }
};

const authReducer = (state, action) => {
    // eslint-disable-next-line default-case
    switch (action.type) {
        case "set-auth-data":
            return {
                ...state,
                ...action.payload,
            };

        case "start-loading":
            return {
                ...state,
                isLoading: true,
            };

        case "stop-loading":
            return {
                ...state,
                isLoading: false,
            };
    }
};

const JumboAuthProvider = ({children, ...restProps}) => {
    const [authOptions, setAuthOptions] = React.useReducer(authReducer, restProps, init);
    const [logout, setLogout] = React.useState(false);
    const navigate = useNavigate();
    const isAuthenticatedRouteOnly = useRoutePathMatch(routesForAuthenticatedOnly);
    const isNotAuthenticatedRouteOnly = useRoutePathMatch(routesForNotAuthenticatedOnly);
    const isPublicRouteOnly = useRoutePathMatch(routesForPublic)

   
    React.useEffect(() => {
        if (logout) {
            removeToken();
            setAuthOptions({
                type: "set-auth-data",
                payload: {authToken: null, authUserId: null, authUser: null, authUserEmail: null, authUserImage: null, userPermissions: null, isLoading: false}
            });
            setLogout(false);
        }
    }, [logout]);

    const setAuthToken = React.useCallback(async (token, id, name, email, image, profile) => {
        setAuthOptions({type: "start-loading"});
        if (!token) {
            setLogout(true);
            return;
        }
        storeToken(token, id, name, email, image, profile);
        try {

            //const authUser = await config?.authSetting/* ?.getAuthUserService() */;

            if (id) {
                setAuthOptions({
                    type: "set-auth-data",
                    payload: {
                        authToken: token, 
                        authUserId: id,
                        authUser: name, 
                        authUserEmail: email, 
                        authUserImage: image,
                        userPermissions: profile, 
                        isLoading: false,
                    }
                });
                return;
            }
            setLogout(true);
        } catch (error) {
            setLogout(true);
            console.error(error);
        }
    }, []);

    //todo: maybe in next version
    const setRedirectPath = React.useCallback((redirectPath) => {
        setAuthOptions({type: "set-redirect-path", payload: {redirectPath}});
    }, []);

    const setAuthData = React.useCallback((data) => {
        setAuthOptions({type: "set-auth-data", payload: data});
    }, []);

    const contextValue = React.useMemo(() => {

        return {
            ...authOptions,
            setAuthData,
            setRedirectPath,
            setAuthToken,
            setAuthOptions,
        }
    }, [authOptions]);


    React.useEffect(() => {
        
        const currentPath = window.location.pathname;
        const match = matchPath(
            { path: "/inscricao-curso-introdutorio/:id/turma/:turmaId" },
            currentPath,
        );
        if (!authOptions.authToken) {
            // usuario sem token e nao logado
            if (
                currentPath === "/esqueci-senha" || 
                currentPath === "/reset-senha" || 
                currentPath === "/cadastro-inicial" ||
                currentPath === "/cadastro" ||
                currentPath === "/obrigado" ||
                currentPath === "/lista-de-cursos" ||
                match
            ) {
                return;
            }
            if (isAuthenticatedRouteOnly) {
                navigate(authOptions?.fallbackPath);
            }
        } else if (!authOptions.authUser) {
            setAuthToken(authOptions.authToken);
        } else if (currentPath === "/login") {
            // usuario com o token e logado e tentando ir para as rotas acima
            if(authOptions.authToken) {
                navigate('/')
            }
            /* if (!firstTimePageLoad)
                navigate(config.authSetting.redirectNotAuthenticatedPath ?? "/");
            else
                firstTimePageLoad = false; */
        }
    }, [authOptions.authUser]);

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export default JumboAuthProvider;