import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  IconButton,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Select,
  MenuItem,
  Skeleton,
  Paper,
} from '@mui/material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import JumboContentLayout from '@jumbo/components/JumboContentLayout';
import { useJumboTheme } from '@jumbo/hooks';
import { ApiService } from 'app/servicesTwo/ApiService';
import { useNavigate } from 'react-router-dom';

const courses = [
  {
    id: 1,
    name: 'Básico do Espiritismo - 1º',
    prerequisites: ['Introdução ao Espiritismo'],
    courseTime: 'Segunda-feira, 19:00 - 21:00',
  },
  {
    id: 2,
    name: 'Básico do Espiritismo - 2º',
    prerequisites: ['Introdução ao Espiritismo', 'Básico do Espiritismo - 1º'],
    courseTime: 'Segunda-feira, 19:00 - 21:00',
  },
  {
    id: 3,
    name: 'Introdução ao Espiritismo',
    prerequisites: [],
    courseTime: 'Segunda-feira, 19:00 - 21:00',
  },
  {
    id: 4,
    name: 'Aprendizes do Evangelho',
    prerequisites: [
      'Introdução ao Espiritismo',
      'Básico do Espiritismo - 1º',
      'Básico do Espiritismo - 2º',
    ],
    courseTime: 'Segunda-feira, 19:00 - 21:00',
  },
  {
    id: 5,
    name: 'Introdução ao Espiritismo',
    prerequisites: [],
    courseTime: 'Segunda-feira, 19:00 - 21:00',
  },
];

const EnrollmentScreen = () => {
  const { theme } = useJumboTheme();
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterPrerequisites, setFilterPrerequisites] = useState('all');
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [data, setData] = useState([]);

  const layoutOptions = React.useMemo(
    () => ({
      sidebar: {
        sx: {
          [theme.breakpoints.up('lg')]: {
            position: 'sticky',
            zIndex: 5,
            top: 96,
            minHeight: 'auto',
          },
          [theme.breakpoints.down('lg')]: {
            display: 'none',
          },
        },
      },
      wrapper: {
        sx: {
          alignItems: 'flex-start',
        },
      },
    }),
    [theme]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilteredCourses(courses);
      setFirstLoading(false);
      setLoading(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

/*   useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      const result = courses.filter((course) => {
        const matchesSearchTerm = course.name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        const matchesPrerequisiteFilter =
          filterPrerequisites === 'all' ||
          (filterPrerequisites === 'with' && course.prerequisites.length > 0) ||
          (filterPrerequisites === 'without' &&
            course.prerequisites.length === 0);
        return matchesSearchTerm && matchesPrerequisiteFilter;
      });
      setFilteredCourses(result);
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [searchTerm, filterPrerequisites]); */

  const getClasses = useCallback(async () => {
    try {
      setLoading(true);

      setTimeout(() => {

      
      const params = {
        filterPrerequisites,
        searchTerm
      }

      ApiService.get('/cursos-introdutorios', {
        params: params
      })
        .then((response) => {
          console.log(response.data);
          setData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('error', error.message);
          }
        });
      }, 2000)

    } catch (err) {
      console.log(err);
    }
  }, [filterPrerequisites, searchTerm]);

  useEffect(() => {
    getClasses();
  }, [filterPrerequisites, searchTerm, getClasses]);

  return (
    <JumboContentLayout layoutOptions={layoutOptions}>
      <Paper sx={{ p: '2rem' }}>
        <Typography variant='h1' gutterBottom>
          {firstLoading ? (
            <Skeleton width={300} height={40} />
          ) : (
            'Cursos Disponíveis para Matrícula'
          )}
        </Typography>

        {/* Filtros */}
        <Box
          display='flex'
          justifyContent={'center'}
          alignItems={'center'}
          gap={2}
          marginBottom={3}
          width={'100%'}
        >
          {firstLoading ? (
            <>
              <Skeleton variant='rectangular' width='100%' height={56} />
              <Skeleton variant='rectangular' width='100%' height={56} />
            </>
          ) : (
            <Box
              display='flex'
              justifyContent={'center'}
              alignItems={'center'}
              gap={1}
              width={'50%'}
            >
              <TextField
                label='Buscar Curso'
                variant='outlined'
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Select
                value={filterPrerequisites}
                onChange={(e) => setFilterPrerequisites(e.target.value)}
                displayEmpty
                fullWidth
              >
                <MenuItem value='all'>Todos os Cursos</MenuItem>
                <MenuItem value='with'>Com Pré-requisitos</MenuItem>
                <MenuItem value='without'>Sem Pré-requisitos</MenuItem>
              </Select>
            </Box>
          )}
        </Box>

        <Grid container spacing={3}>
          {loading ? (
            Array.from({ length: 6 }).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton variant='rectangular' width='100%' height={300} />
              </Grid>
            ))
          ) : data.length > 0 ? (
            data.map((item) =>
              item?.Turma.map((el) => (
                <Grid item xs={12} sm={6} md={4} key={el.turmas_id}>
                  <Card
                    elevation={5}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      background: '#f9f9f9',
                      borderRadius: '8px',
                      overflow: 'hidden',
                    }}
                  >
                    <Box
                      sx={{
                        /*   background: 'linear-gradient(90deg, #1976d2, #42a5f5)',
                      color: 'white', */
                        background: '#e3f2fd',
                        textAlign: 'center',
                        padding: '0.8rem',
                      }}
                    >
                      <Typography
                        variant='h2'
                        gutterBottom
                        sx={{ fontWeight: 'bold', letterSpacing: 1 }}
                      >
                        {el?.Curso?.nome}
                      </Typography>
                    </Box>

                    <CardContent sx={{ flexGrow: 1, padding: '1.5rem' }}>
                      <Typography variant='subtitle1' sx={{ marginBottom: 1 }}>
                        Pré-requisitos:
                      </Typography>
                      {el?.Curso?.Curso?.length > 0 ? (
                        <List>
                          {el?.Curso?.Curso?.map((prerequisite, index) => (
                            <ListItem key={index} disablePadding>
                              <ListItemIcon>
                                <CheckCircleIcon
                                  color='success'
                                  fontSize='small'
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary={prerequisite?.pre_requisito?.nome}
                              />
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <Typography
                          variant='body2'
                          color='textSecondary'
                          fontSize='small'
                        >
                          Nenhum pré-requisito
                        </Typography>
                      )}

                      <Typography
                        variant='body1'
                        color='textSecondary'
                        sx={{ marginTop: 2 }}
                      >
                        <strong>Dia da Semana:</strong> {el?.dia_da_semana}
                      </Typography>

                      <Typography
                        variant='body1'
                        color='textSecondary'
                        
                      >
                        <strong>Horário:</strong> {el?.horario}
                      </Typography>
                    </CardContent>

                    <CardActions
                      sx={{
                        justifyContent: 'center',
                        /* background: '#e3f2fd', */
                        padding: '1rem',
                      }}
                    >
                      <Button
                        variant='contained'
                        size='small'
                        color='info'
                        startIcon={<AssignmentTurnedInIcon />}
                        onClick={() =>
                          window.open(`/inscricao-curso-introdutorio/${item.curso_indrodutorio_id}/turma/${el.turmas_id}`, '_blank')
                        }
                      >
                        Fazer Inscrição
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))
            )
          ) : (
            <Typography
              variant='body1'
              color='textSecondary'
              sx={{ margin: 'auto', textAlign: 'center' }}
            >
              Nenhum curso encontrado.
            </Typography>
          )}
        </Grid>

        <Grid container spacing={3} mt={3}>
          {loading ? (
            Array.from({ length: 6 }).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton variant='rectangular' width='100%' height={200} />
              </Grid>
            ))
          ) : filteredCourses.length > 0 ? (
            filteredCourses.map((course) => (
              <Grid item xs={12} sm={6} md={4} key={course.id}>
                <Card
                  elevation={3}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography variant='h2' gutterBottom>
                      {course.name}
                    </Typography>
                    <Box>
                      <Typography variant='subtitle1' sx={{ marginBottom: 1 }}>
                        Pré-requisitos:
                      </Typography>
                      {course.prerequisites.length > 0 ? (
                        <List>
                          {course.prerequisites.map((prerequisite, index) => (
                            <ListItem key={index} disablePadding>
                              <ListItemIcon>
                                <CheckCircleIcon
                                  color='primary'
                                  fontSize='small'
                                />
                              </ListItemIcon>
                              <ListItemText primary={prerequisite} />
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <Typography
                          variant='body2'
                          color='textSecondary'
                          fontSize='small'
                        >
                          Nenhum pré-requisito
                        </Typography>
                      )}
                      <Typography
                        variant='body1'
                        color='textSecondary'
                        sx={{ marginTop: 2 }}
                      >
                        <strong>Horário:</strong> {course.courseTime}
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <IconButton color='primary' aria-label='fazer inscrição'>
                      <AssignmentTurnedInIcon />
                    </IconButton>
                    <Button size='small' color='primary'>
                      Fazer Inscrição
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography
              variant='body1'
              color='textSecondary'
              sx={{ margin: 'auto', textAlign: 'center' }}
            >
              Nenhum curso encontrado.
            </Typography>
          )}
        </Grid>
      </Paper>
    </JumboContentLayout>
  );
};

export default EnrollmentScreen;
