import React from "react";
import Home from "../pages/home";
import Login from "../pages/login";
import ContactsApp from '../pages/app/contacts/ContactsApp'
import Page from "@jumbo/shared/Page";
import UserForm from "../pages/app/users/UserForm";
import UserList from "../pages/app/users/UserList";
import ClassList from "../pages/app/classes/ClassList";
import ClassForm from "../pages/app/classes/ClassForm";
import CourseForm from "../pages/app/courses/CourseForm";
import CourseList from "../pages/app/courses/CourseList";
import ModalCourseHistory from "../pages/app/course_histories/ModalCourseHistory";
import DepartmentList from "../pages/app/departments/DepartmentList";
import DepartmentForm from "../pages/app/departments/DepartmentForm";
import AreaList from "app/pages/app/areas/AreaList";
import AreaForm from "app/pages/app/areas/AreaForm";
import LogList from "../pages/app/logs/LogList";
import ProfileList from "app/pages/app/profiles/ProfileList";
import ProfileForm from "app/pages/app/profiles/ProfileForm";
import ProtectedRoute from "app/routes/ProtectedRoute";
import UserRegistrationForm from "../pages/app/registerUsers/UserRegistrationForm";
import UserRegistrationList from "../pages/app/registerUsers/UserRegistrationList";
import ForgotPassword from "app/pages/forgotPassword/ForgotPassword";
import ResetPassword from "app/pages/resetPassword/ResetPassword";
import VolunteerInterestedList from "app/pages/app/volunteersInterested/VolunteerInterestedList";
import VolunteerInterestedFormResume from "app/pages/app/volunteersInterested/VolunteerInterestedFormResume";
import VolunteerList from "app/pages/app/volunteers/VolunteerList";
import VolunteerFormResume from "app/pages/app/volunteers/VolunteerFormResume";
import AssociateList from "app/pages/app/associates/AssociateList";
import AssociateFormResume from "app/pages/app/associates/AssociateFormResume";
import CouncilList from "app/pages/app/council/CouncilList";
import CouncilFormResume from "app/pages/app/council/CouncilFormResume"
import CoursesIntroList from "app/pages/app/coursesIntro/CoursesIntroList";
import CoursesIntroForm from "app/pages/app/coursesIntro/CoursesIntroForm";
import JoinCoursesIntroForm from "app/pages/joinCoursesIntroForm";
import RegisterUserFormPublic from "app/pages/registerUserPublic/RegisterUserFormPublic";
import Thanks from "app/pages/thanks/Thanks";
import Unauthorized from "app/pages/unauthorized/Unauthorized";
import Signup from "app/pages/signUp/SignUp";
import ActivitiesList from "app/pages/app/activities/ActivitiesList";
import ActivitiesForm from "app/pages/app/activities/ActivitiesForm";
import VolunteerPrintingList from "app/pages/app/volunteerPrinting/VolunteerPrintingList";
import EvaluateDocumentsList from "app/pages/app/evaluateDocuments/EvaluateDocumentsList";
import BatchCourseLaunchList from "app/pages/app/batchCourseLaunch/BatchCourseLaunchList";
import CoursesFromOtherEntitiesLaunchList from "app/pages/app/coursesFromOtherEntitiesLaunch/CoursesFromOtherEntitiesLaunchList";
import UserCourseHistoryList from "app/pages/app/usersCourseHistory/UserCourseHistoryList";
import ClassAddStudentForm from "app/pages/app/classes/ClassAddStudentForm";
import SpiritualCenterList from "app/pages/app/spiritualCenters/SpiritualCenterList";
import SpiritualCenterForm from "app/pages/app/spiritualCenters/SpiritualCenterForm";
import VolunteerAlocationList from "app/pages/app/volunteersAlocation/VolunteerAlocationList";
import VolunteerAlocationFormResume from "app/pages/app/volunteersAlocation/VolunteerAlocationFormResume";
import BookList from "app/pages/app/book/BookList";
import BookForm from "app/pages/app/book/BookForm";
import BookTeamList from "app/pages/app/bookTeam/BookTeamList";
import BookTeamForm from "app/pages/app/bookTeam/BookTeamForm";
import VolunteerHistoryList from "app/pages/app/volunteersHistory/VolunteerHistoryList";
import RoomList from "app/pages/app/room/RoomList";
import RoomForm from "app/pages/app/room/RoomForm";
import DirectorList from "app/pages/app/directors/DirectorsList";
import DirectorForm from "app/pages/app/directors/DirectorForm";
import UserCertifiedList from "app/pages/app/usersCertified/UserCertifiedList";
import EnrollmentScreen from "app/pages/EnrollmentScreen";



/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [
    {
        path: "/login",
        element: <Page component={Login} layout={"solo-page"} />
    },
    {
        path: "/esqueci-senha",
        element: <Page component={ForgotPassword} layout={"solo-page"} />
    },

    {
        path: "/reset-senha",
        element: <Page component={ResetPassword} layout={"solo-page"} />
    },
    {
        path: "/inscricao-curso-introdutorio/:id/turma/:turmaId",
        element: <Page component={JoinCoursesIntroForm} layout={"solo-page"} />
    },
    {
        path: "/cadastro-inicial",
        element: <Page component={RegisterUserFormPublic} layout={"solo-page"} />
    },
    {
        path: "/obrigado",
        element: <Page component={Thanks} layout={"solo-page"} />
    },
    {
        path: "/acesso-negado",
        element: <Page component={Unauthorized} layout={"solo-page"} />
    },
    {
        path: "/cadastro",
        element: <Page component={Signup} layout={"solo-page"} />
    },
    {
        path: "/lista-de-cursos",
        element: <Page component={EnrollmentScreen} layout={"solo-page"} />
    },
];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <Page component={Home} />,
    },
    {
        path: "/app/contatos",
        element: <Page component={ContactsApp} />
    },
    {
        path: "/app/listar-consultar-historico-voluntariado",
        element: 
        <ProtectedRoute 
            permission={'Consultar Histórico de Voluntário'} 
            requiredRole={'acessar'}
            component={VolunteerHistoryList}
        />
    },
    {
        path: "/app/listar-certificados",
        element: 
        <ProtectedRoute 
            permission={'Certificados'} 
            requiredRole={'acessar'}
            component={UserCertifiedList}
        />
    },
    {
        path: "/app/listar-consultar-historico",
        element: 
        <ProtectedRoute 
            permission={'Consultar Histórico de Cursos'} 
            requiredRole={'acessar'}
            component={UserCourseHistoryList}
        />
    },
    {
        path: "/app/listar-usuarios",
        element: 
        <ProtectedRoute 
            permission={'Pessoas'} 
            requiredRole={'acessar'}
            component={UserList}
        />
    },
    {
        path: "/app/novo-aluno",
        element:
        <ProtectedRoute 
            permission={'Pessoas'} 
            requiredRole={'criar'}
            component={UserForm}
        />
    },
    {
        path: "/app/editar-aluno/:id",
        element:
        <ProtectedRoute 
            permission={'Pessoas'} 
            requiredRole={'acessar'}
            component={UserForm}
        />
    },
    {
        path: "/app/listar-centros-espiritas",
        element:
        <ProtectedRoute 
            permission={'Centros Espíritas'} 
            requiredRole={'acessar'}
            component={SpiritualCenterList}
        />
    },
    {
        path: "/app/novo-centro-espirita",
        element:
        <ProtectedRoute 
            permission={'Centros Espíritas'} 
            requiredRole={'criar'}
            component={SpiritualCenterForm}
        />
    },
    {
        path: "/app/editar-centro-espirita/:id",
        element:
        <ProtectedRoute 
            permission={'Centros Espíritas'} 
            requiredRole={'acessar'}
            component={SpiritualCenterForm}
        />
    },
    {
        path: "/app/listar-salas",
        element: 
        <ProtectedRoute 
            permission={'Salas'} 
            requiredRole={'acessar'}
            component={RoomList}
        />
    },
    {
        path: "/app/nova-sala",
        element:
        <ProtectedRoute 
            permission={'Salas'} 
            requiredRole={'criar'}
            component={RoomForm}
        />
    },
    {
        path: "/app/editar-sala/:id",
        element:
        <ProtectedRoute 
            permission={'Salas'} 
            requiredRole={'acessar'}
            component={RoomForm}
        />
    },
    {
        path: "/app/listar-diretores",
        element: 
        <ProtectedRoute 
            permission={'Diretores'} 
            requiredRole={'acessar'}
            component={DirectorList}
        />
    },
    {
        path: "/app/novo-diretor",
        element:
        <ProtectedRoute 
            permission={'Diretores'} 
            requiredRole={'criar'}
            component={DirectorForm}
        />
    },
    {
        path: "/app/editar-diretor/:id",
        element:
        <ProtectedRoute 
            permission={'Diretores'} 
            requiredRole={'acessar'}
            component={DirectorForm}
        />
    },
    {
        path: "/app/listar-areas",
        element:
        <ProtectedRoute 
            permission={'Áreas'} 
            requiredRole={'acessar'}
            component={AreaList}
        />
    },
    {
        path: "/app/nova-area",
        element:
        <ProtectedRoute 
            permission={'Áreas'} 
            requiredRole={'criar'}
            component={AreaForm}
        />
    },
    {
        path: "/app/editar-area/:id",
        element:
        <ProtectedRoute 
            permission={'Áreas'} 
            requiredRole={'acessar'}
            component={AreaForm}
        />
    },

    {
        path: "/app/listar-atividades",
        element:
        <ProtectedRoute 
            permission={'Atividades'} 
            requiredRole={'acessar'}
            component={ActivitiesList}
        />
    },
    {
        path: "/app/nova-atividade",
        element:
        <ProtectedRoute 
            permission={'Atividades'} 
            requiredRole={'criar'}
            component={ActivitiesForm}
        />
    },
    {
        path: "/app/editar-atividade/:id",
        element:
        <ProtectedRoute 
            permission={'Atividades'} 
            requiredRole={'acessar'}
            component={ActivitiesForm}
        />
    },


    {
        path: "/app/listar-cursos",
        element: 
        <ProtectedRoute 
            permission={'Cursos'} 
            requiredRole={'acessar'}
            component={CourseList}
        />
    },
    {
        path: "/app/novo-curso",
        element:
        <ProtectedRoute 
            permission={'Cursos'} 
            requiredRole={'criar'}
            component={CourseForm}
        />
    },
    {
        path: "/app/editar-curso/:id",
        element:
        <ProtectedRoute 
            permission={'Cursos'} 
            requiredRole={'acessar'}
            component={CourseForm}
        />
    },
    {
        path: "/app/historico-cursos",
        element: <Page component={ModalCourseHistory} />
    },
    {
        path: "/app/listar-turmas",
        element:
        <ProtectedRoute 
            permission={'Turmas'} 
            requiredRole={'acessar'}
            component={ClassList}
        />
    },
    {
        path: "/app/nova-turma",
        element: 
        <ProtectedRoute 
            permission={'Turmas'} 
            requiredRole={'criar'}
            component={ClassForm}
        />
    },
    {
        path: "/app/editar-turma/:id",
        element: 
        <ProtectedRoute 
            permission={'Turmas'} 
            requiredRole={'acessar'}
            component={ClassForm}
        />
    },
    {
        path: "/app/listar-turmas-inserir-aluno",
        element:
        <ProtectedRoute 
            permission={'Inclusão de Alunos na Turma'} 
            requiredRole={'acessar'}
            component={ClassList}
        />
    },
    {
        path: "/app/inserir-aluno-turma/:id",
        element: 
        <ProtectedRoute 
            permission={'Inclusão de Alunos na Turma'} 
            requiredRole={'acessar'}
            component={ClassAddStudentForm}
        />
    },
    {
        path: "/app/listar-departamentos",
        element:
        <ProtectedRoute 
            permission={'Departamentos'}  
            requiredRole={'acessar'}
            component={DepartmentList}
        />
    },
    {
        path: "/app/novo-departamento",
        element:
        <ProtectedRoute 
            permission={'Departamentos'}  
            requiredRole={'criar'}
            component={DepartmentForm}
        />
    },
    {
        path: "/app/editar-departamento/:id",
        element:
        <ProtectedRoute 
            permission={'Departamentos'}  
            requiredRole={'acessar'}
            component={DepartmentForm}
        />
    },
    {
        path: "/app/log-de-atividades",
        element:
        <ProtectedRoute 
            permission={'Log de Atividades'}  
            requiredRole={'acessar'}
            component={LogList}
        />
    },
    {
        path: "/app/listar-perfis-de-acesso",
        element:
        <ProtectedRoute 
            permission={'Perfis'}  
            requiredRole={'acessar'}
            component={ProfileList}
        />
    },
    {
        path: "/app/novo-perfil",
        element:
        <ProtectedRoute 
            permission={'Perfis'}  
            requiredRole={'criar'}
            component={ProfileForm}
        />
    },
    {
        path: "/app/editar-perfil/:id",
        element: 
        <ProtectedRoute 
            permission={'Perfis'}  
            requiredRole={'acessar'}
            component={ProfileForm}
        />
    },

    {
        path: "/app/listar-usuarios-registrados",
        element: 
        <ProtectedRoute 
            permission={'Usuários'} 
            requiredRole={'acessar'}
            component={UserRegistrationList}
        />
    },
    {
        path: "/app/novo-usuario",
        element:
        <ProtectedRoute 
            permission={'Usuários'} 
            requiredRole={'criar'}
            component={UserRegistrationForm}
        />
    },
    {
        path: "/app/editar-usuario/:id",
        element:
        <ProtectedRoute 
            permission={'Usuários'} 
            requiredRole={'acessar'}
            component={UserRegistrationForm}
        />
    },
    {
        path: "/app/editar-interesse-voluntariado-resume/:id",
        element:
        <ProtectedRoute 
            permission={'Voluntariado'} 
            requiredRole={'acessar'}
            component={VolunteerInterestedFormResume}
        />

    },
    {
        path: "/app/novo-interesse-voluntariado",
        element: 
        <ProtectedRoute 
            permission={'Voluntariado'} 
            requiredRole={'criar'}
            component={VolunteerInterestedFormResume}
        />
    },
    
    {
        path: "/app/listar-interesse-voluntariado",
        element:
        <ProtectedRoute 
            permission={'Voluntariado'} 
            requiredRole={'acessar'}
            component={VolunteerInterestedList}
        />
    },
    {
        path: "/app/listar-voluntariado",
        element:
        <ProtectedRoute 
            permission={'Voluntariado'} 
            requiredRole={'acessar'}
            component={VolunteerList}
        />
    },
    {
        path: "/app/novo-voluntariado",
        element: 
        <ProtectedRoute 
            permission={'Voluntariado'} 
            requiredRole={'criar'}
            component={VolunteerFormResume}
        />
    },
    {
        path: "/app/editar-voluntariado-resume/:id",
        element:
        <ProtectedRoute 
            permission={'Voluntariado'} 
            requiredRole={'acessar'}
            component={VolunteerFormResume}
        />

    },
    {
        path: "/app/listar-alocar-voluntariado",
        element:
        <ProtectedRoute 
            permission={'Voluntariado'} 
            requiredRole={'acessar'}
            component={VolunteerAlocationList}
        />
    },
    {
        path: "/app/listar-equipes",
        element: 
        <ProtectedRoute 
            permission={'Livros (Equipe)'} 
            requiredRole={'acessar'}
            component={BookList}
        />
    },
    {
        path: "/app/nova-equipe",
        element: 
        <ProtectedRoute 
            permission={'Livros (Equipe)'} 
            requiredRole={'criar'}
            component={BookForm}
        />
    },
    {
        path: "/app/editar-equipe/:id",
        element: 
        <ProtectedRoute 
            permission={'Livros (Equipe)'} 
            requiredRole={'criar'}
            component={BookForm}
        />
    },
    {
        path: "/app/listar-equipes-inserir-voluntario",
        element: 
        <ProtectedRoute 
            permission={'Alocação de Colaboradores'} 
            requiredRole={'acessar'}
            component={BookTeamList}
        />
    },
    {
        path: "/app/inserir-voluntario-equipe/:id",
        element: 
        <ProtectedRoute 
            permission={'Alocação de Colaboradores'} 
            requiredRole={'acessar'}
            component={BookTeamForm}
        />
    },
    {
        path: "/app/editar-alocar-voluntariado-resume/:id",
        element:
        <ProtectedRoute 
            permission={'Alocação de Colaboradores'} 
            requiredRole={'acessar'}
            component={VolunteerAlocationFormResume}
        />

    },
    {
        path: "/app/impressao-de-crachas",
        element: 
        <ProtectedRoute 
            permission={'Voluntariado'} 
            requiredRole={'acessar'}
            component={VolunteerPrintingList}
        />
    },
    {
        path: "/app/listar-associados",
        element: 
        <ProtectedRoute 
            permission={'Associados'} 
            requiredRole={'acessar'}
            component={AssociateList}
        />
    },
    {
        path: "/app/novo-associado",
        element: 
        <ProtectedRoute 
            permission={'Associados'} 
            requiredRole={'criar'}
            component={AssociateFormResume}
        />
    },
    {
        path: "/app/editar-associado/:id",
        element:
        <ProtectedRoute 
            permission={'Associados'} 
            requiredRole={'acessar'}
            component={AssociateFormResume}
        />

    },
    {
        path: "/app/listar-conselhos",
        element: 
        <ProtectedRoute 
            permission={'Conselho Deliberativo'} 
            requiredRole={'acessar'}
            component={CouncilList}
        />
    },
    {
        path: "/app/novo-conselho",
        element: 
        <ProtectedRoute 
            permission={'Conselho Deliberativo'} 
            requiredRole={'criar'}
            component={CouncilFormResume}
        />
    },
    {
        path: "/app/editar-conselho/:id",
        element:
        <ProtectedRoute 
            permission={'Conselho Deliberativo'} 
            requiredRole={'acessar'}
            component={CouncilFormResume}
        />

    },
    {
        path: "/app/listar-inscricoes-abertas",
        element: 
        <ProtectedRoute 
            permission={'Setup de Curso no Ano'} 
            requiredRole={'acessar'}
            component={CoursesIntroList}
        />
    },
    {
        path: "/app/novo-abrir-inscricoes",
        element: 
        <ProtectedRoute 
            permission={'Setup de Curso no Ano'} 
            requiredRole={'criar'}
            component={CoursesIntroForm}
        />
    },
    {
        path: "/app/editar-abrir-inscricoes/:id",
        element:
        <ProtectedRoute 
            permission={'Setup de Curso no Ano'} 
            requiredRole={'acessar'}
            component={CoursesIntroForm}
        />

    },

    {
        path: "/app/listar-avaliacao-de-documentos",
        element:
        <ProtectedRoute 
            permission={'Avaliação de Documentos'} 
            requiredRole={'acessar'}
            component={EvaluateDocumentsList}
        />

    },
    {
        path: "/app/listar-curso-lote",
        element:
        <ProtectedRoute 
            permission={'Lançamento de Curso em Lote'} 
            requiredRole={'acessar'}
            component={BatchCourseLaunchList}
        />

    },
    {
        path: "/app/listar-cursos-outras-entidades",
        element:
        <ProtectedRoute 
            permission={'Inclusão de Cursos de Outras Entidades'} 
            requiredRole={'acessar'}
            component={CoursesFromOtherEntitiesLaunchList}
        />

    },
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    
];


const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export {routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly};